import ContentLoader from 'react-content-loader';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { Color } from '../enums/Color';

const styles = {
    mobile: {        
        margin: "50px 0 0 0"      
    },
    desktop: {
        margin: "58px 0 20px 0",
        padding: "0 32%"
    }
};

export const ClassDescriptionLoader: React.FC = () => {            
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles = styles.mobile;
    if (!isMobile) {
        let extendStyles = merge(styles.mobile, styles.desktop);
        loaderStyles = extendStyles;
    }
    return (                         
        <div style={loaderStyles}>
            <ContentLoader
                speed={1}
                width={"100%"}
                height={"100vh"}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                {isMobile ? <>
                    <rect x="0" y="0" rx="0" ry="0" width="100vw" height="100vw" />
                    <rect x="5%" y="106vw" rx="5" ry="5" width="60vw" height="8vw" />
                    <rect x="5%" y="118vw" rx="5" ry="5" width="80vw" height="8vw" />
                    <rect x="5%" y="130vw" rx="5" ry="5" width="90vw" height="8vw" />
                    <rect x="5%" y="142vw" rx="5" ry="5" width="70vw" height="8vw" />
                    <rect x="5%" y="154vw" rx="5" ry="5" width="80vw" height="8vw" />
                    <rect x="5%" y="166vw" rx="5" ry="5" width="90vw" height="8vw" />
                    <rect x="5%" y="178vw" rx="5" ry="5" width="50vw" height="8vw" />
                </> : 
                <>
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="36vw" />
                    <rect x="0" y="38vw" rx="10" ry="10" width="60%" height="2vw" />
                    <rect x="0" y="41vw" rx="10" ry="10" width="80%" height="2vw" />
                    <rect x="0" y="44vw" rx="10" ry="10" width="100%" height="2vw" />
                    <rect x="0" y="47vw" rx="10" ry="10" width="70%" height="2vw" />
                    <rect x="0" y="50vw" rx="10" ry="10" width="80%" height="2vw" />
                    <rect x="0" y="53vw" rx="10" ry="10" width="100%" height="2vw" />
                </>}
            </ContentLoader>
        </div>
    );
}