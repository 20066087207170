import { merge } from "react-merge"
import { useMediaQuery } from "react-responsive"
import { Color } from "../enums/Color"
import ContentLoader from "react-content-loader"

const loaderStyle = {
    mobile : {
        width: "90.34vw",
        height: "57vw",
        backgroundColor: Color.White, 
        padding: "0 4.83vw",
        margin: "24.15vw 0 51.69vw"        
    },
    desktop: {
        borderRadius: "0.52vw",
        border: `2px solid ${Color.LightAsh}`,
        padding: "2.6vw",
        width: "29.75vw",
        height: "15.75vw",
        margin: "unset"        
    }
};

export const LostPasswordLoader : React.FC = () => {
    const isMobile = useMediaQuery({query : "(max-width: 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }
    
    return (
        <div style={styles}>
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width="auto"
                height={styles.height}
            > 
                {isMobile ? <>
                    <rect x="4.27%" y="0" rx="3" ry="3" width="64.80%" height="11.86%" />
                    <rect x="4.27%" y="22.46%" rx="3" ry="3" width="82.40%" height="9.75%" />
                    <rect x="0" y="42.80%" rx="3" ry="3" width="100%" height="21.19%" />
                    <rect x="0" y="78.81%" rx="3" ry="3" width="100%" height="21.19%" />
                </> : 
                <>
                    <rect x="1.75%" y="0" rx="3" ry="3" width="51.14%" height="11.26%" />
                    <rect x="1.75%" y="21%" rx="3" ry="3" width="51.14%" height="6.62%" />
                    <rect x="0" y="33%" rx="3" ry="3" width="100%" height="23.18%" />
                    <rect x="0" y="66%" rx="3" ry="3" width="100%" height="23.18%" />
                </>} 
            </ContentLoader>
        </div>
    )
}