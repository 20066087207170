import { Color } from "../enums/Color";

export const contactPage = {
    mobile: {        
        toggleWrapper: {
            position: "relative" as "relative",
            display: "flex",
            flexDirection: "column" as "column",
            margin: "-11.83vh 5% 0 5%",
            zIndex: 1
        },
        image: {
            borderRadius: "4.83vw",
            objectFit: "cover"
        },
        title: {
            fontSize: "6.03vw",
            fontWeight: "bold" as "bold",
            color: Color.LightBlack,
            marginTop: "9.66vw"
        },
        form: {
            marginTop: "3.57vh"
        },
        container: {
            width: "100%"
        },
        communication: {
            container: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                margin: "12vw 0 0 5%",
                rowGap: "2.79vh",
                columnGap: "7.72vw"
            },
            icon: {
                with: "6.03vw",
                height: "2.67vh",
                marginRight: "2.41vw",
                fill: Color.Black
            },
            title: {
                display: "flex",
                fontSize: "4.34vw",
                fontWeight: "bold" as "bold",
                color: Color.Black,
                marginBottom: "2.23vh"
            },
            value: {
                color: Color.SharpAsh
            }
        },
        submitButton: {
            width: "100%",
            height: "12.08vw",
            marginTop: "3.57vh",
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "2.41vw",
            fontSize: "4.34vw",
            fontWeight: "bold" as "bold"
        }
    },
    desktop: {
        wrapper: {
            height: "100%",
            margin: "0 0 -80px 0",
            padding: "3vw 0"
        },
        toggleWrapper: {
            flexDirection: "row" as "row",
            backgroundColor: Color.White,
            border: "0.20vw solid " + Color.Ash,            
            borderRadius: "1.04vw",
            margin: "0 7.29vw"
        },
        image: {
            width: "43.12vw",
            height: "28.75vw",
            borderRadius: "1.04vw 0 0 0",
            backgroundColor: Color.AliceBlue
        },
        title: {
            fontSize: "1.56vw",
            color: Color.Black,
            margin: "2.5vw 0 0 2.60vw"
        },
        form: {
            margin: "2.13vw 2.60vw 0 2.60vw"
        },
        communicationWrapper: {
            borderRight: "0.10vw solid " + Color.LightAsh
        },
        communication: {
            container: {
                gridTemplateColumns: "repeat(3, 1fr)",
                margin: "3.12vw 0 3.125vw 3.12vw",
                rowGap: "0vw",
                columnGap: "3.64vw"
            },
            icon: {
                width: "1.14vw",
                height: "1.25vw",
                marginRight: "0.52vw"
            },
            title: {
                fontSize: "0.93vw",
                marginBottom: "0"
            },
            value: {
                fontSize: "0.93vw",
                marginLeft: "1.7vw",
                paddingBottom: "0.2vw"
            }
        },
        submitButton: {
            width: "14.16vw",
            height: "3.64vw",
            margin: "2.00vw 2.60vw 2.5vw 0",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            float: "right" as "right"
        }
    }
}