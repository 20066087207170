import React from "react"
import ContentLoader from "react-content-loader"
import { Color } from "../enums/Color"

interface Props {
    styles?: any;
}

export const InputLoader: React.FC<Props> = (props) => {
    return (
        <div style={props.styles}>
            <ContentLoader 
                speed={2}
                width={"100%"}
                height={80}                
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >
                <rect x="0" y="60" rx="3" ry="3" width="80%" height="20" /> 
                <rect x="90%" y="60" rx="3" ry="3" width="5%" height="20" />                               
            </ContentLoader>
        </div>
    )
}