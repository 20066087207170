import axios from "axios";
import { CommonTexts as Texts } from "../helpers/LayoutTexts";
import { LoginModel } from "../models/LoginModel";
import { RegisterModel } from "../models/RegisterModel";
import { EmailPasswordModel } from "../models/EmailPasswordModel";

export async function postStudentLogin(loginModel: LoginModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "student/login", loginModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postStudentLogout(studentId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "student/logout?studentId=" + studentId, {}, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });    
}

export async function validateStudentLogin(studentId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "student/validate?studentId=" + studentId, {}, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });    
}

export async function postStudentRegistration(registerModel: RegisterModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "student/register", registerModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postLinkedInAuthCode(authCode: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "student/authenticateWithLinkedIn?authCode=" + authCode)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function postLostPasswordRequest(email: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .post(Texts.apiBaseUrl + "student/lostPassword?email=" + email, {})
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getResetPasswordValidity(email: string, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(Texts.apiBaseUrl + "student/resetPasswordValidity", {            
            params: {      
                email: email,
                token: token
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postResetPassword(passwordModel: EmailPasswordModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "student/resetPassword", passwordModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}