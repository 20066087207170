import { useContext, useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Localization } from "../../models/Localization";
import { useMediaQuery } from "react-responsive";
import { getPageLocalizations } from "../../services/CommonService";
import { OnlineClass } from "../../models/OnlineClass";
import { getSeoName } from "../../helpers/Common";
import { ToolBar } from "../../components/Shared/ToolBar";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { CardListLoader } from "../../loaders/CardListLoader";
import { NoData } from "../../components/Shared/NoData";
import { Pagination } from "../../components/Shared/Pagination";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { getClassesByStudent } from "../../services/ClassService";
import { LoggedStudent } from "../../models/LoggedStudent";
import { Button } from "../../components/Shared/Button";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar.svg";

export const StudentClassPage: React.FC = () => { 
    const navigate = useNavigate(); 
    const location = useLocation();   
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassPage")); 
    const authContext = useContext(AuthContext); 
    const student: LoggedStudent | null = authContext.getLoggedStudent(); 
    const [loading, setLoading] = useState<boolean>(true);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);  
    const [onlineClasses, setOnlineClasses] = useState<OnlineClass[]>([]);                              
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();      
    const pageSize: number = 20;           
    const scrollElementRef = useRef<any>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const subjectCharacterLength: number = isMobile ? 42 : 84;    
    const gradeCharacterLength: number = isMobile ? 42 : 84;
    const bannerType = isMobile ? 7 : 8;

    useEffect(() => {     
        if(student !== null) {
            getLocalizations();                                          
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login or register as student to access the your class page." };
            navigate({
                pathname: '/student/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }      
    }, []);

    useEffect(() => {                    
        getOnlineClassList();                     
    }, [page]);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("onlineClassPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 36)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getOnlineClassList() {          
        
        await getClassesByStudent(page, pageSize, student!.StudentId, student!.Token)
        .then((onlineClassList: OnlineClass[]) => {
            setOnlineClasses(onlineClassList);               
            if(onlineClassList.length > 0) { 
                let totalPageCount = Math.ceil(onlineClassList[0].RecordCount! / pageSize);            
                setTotalPages(totalPageCount);
            }    
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }
    
    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString()); 
        scrollElementRef.current.scrollIntoView();
        handleNavigate();                                    
    }

    const handleNavigate = () => {                                    
        const newUrl = "/student/online-classes" + `?${searchParams}`;
        navigate(newUrl);                
    }

    const toClassView = (onlineClass: OnlineClass) => {
        let pageSEO = `${onlineClass.TeacherName} ${onlineClass.Subjects} online class`;
        pageSEO = pageSEO.trim();
        pageSEO = getSeoName(pageSEO);
        navigate("/online-class/description/" + onlineClass.OnlineClassId + "/" + pageSEO);
    }

    const toClassWeeks = (event: any, classId: number) => {  
        event.stopPropagation();      
        navigate("/student/class-weeks/" + classId);
    }

    return (
        <>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>                                           
                    <div style={styles.class.subContainer} ref={scrollElementRef}>
                        {loading ? <CardListLoader/> : <>
                            {onlineClasses.length === 0 ? <NoData/> : <>                                                       
                                {onlineClasses.map((onlineClass, index) =>
                                    <div key={index} style={styles.class.card} onClick={() => toClassView(onlineClass)}>                                
                                        <div style={styles.class.imageWrapper}>
                                            <img src={onlineClass.ClassImageUrl} alt="ad image" style={styles.class.image}></img>
                                        </div>
                                        <div style={styles.class.detailWrapper}>
                                            <div style={styles.class.name}>{onlineClass.TeacherName}</div>
                                            {onlineClass.Subjects && <div style={styles.class.subjects}>
                                                {onlineClass.Subjects.length > subjectCharacterLength ? onlineClass.Subjects.slice(0, subjectCharacterLength) + " ..." : onlineClass.Subjects}
                                            </div>}  
                                            {onlineClass.Grades && <div style={styles.class.grades}>
                                                {onlineClass.Grades.length > gradeCharacterLength ? onlineClass.Grades.slice(0, gradeCharacterLength) + " ..." : onlineClass.Grades}
                                            </div>}
                                            <div style={styles.class.buttonWrapper}> 
                                                <Button style={styles.class.weekButton} onClick={(e) => toClassWeeks(e, onlineClass.OnlineClassId)}>
                                                    <CalendarIcon style={styles.class.weekButton.icon} />{" Weeks"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination} />}
                            </>}
                        </>}
                    </div> 
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
        </>
    )
}