import React from 'react';
import { ReactComponent as Logo } from '../assets/svgs/tuteclass-logo.svg';

const LogoLoader: React.FC = () => {
  return (
    <div className="spinner-container">
      <Logo className="spinner-logo" />
    </div>
  );
};

export default LogoLoader;