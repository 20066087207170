import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const errorPage = {
    mobile: {
        container: {
            textAlign: "center",
            overflow: "hidden"
        },
        oopsText: {
            fontSize: "30.19vw",
            fontFamily: Font.Advanced,
            color: Color.Green,
            fontStyle: "italic",
            marginTop: "36.82vw"          
        },
        connectText: {
            fontSize: "5.80vw",
            fontFamily: Font.Advanced,
            color: Color.Black,
            margin: "4.35vw auto"
        },
        unavailableText: {
            width: "80%",
            fontSize: "3.38vw",
            fontWeight: "bold",
            color: Color.SharpAsh,
            margin: "2.90vh auto 0 auto"
        },
        backButton: {
            width: "51%",
            height: "10.60vw",
            marginTop: "10.14vw",
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "2.41vw",
            fontSize: "3.35vw",
            fontWeight: "bold"
        },
        copyright: {
            position: "relative" as "relative",
            width: "80%",
            fontSize: "2.66vw",
            color: Color.SharpAsh,
            margin: "3.01vh auto",
            fontWeight: "bold"
        }
    },
    desktop: {
        container: {
            height: "100%"
        },
        oopsText: {
            fontSize: "18.11vw",
            marginTop: "3.125vw"                   
        },
        connectText: {
            width: "auto",
            fontSize: "2.19vw",
            margin: "1.35vw auto 0 auto"
        },
        unavailableText: {
            width: "auto",
            fontSize: "0.83vw",
            margin: "0.3vw auto 0 auto"
        },
        backButton: {
            width: "17.19vw",
            height: "2.60vw",
            borderRadius: "0.52vw",
            marginTop: "4.17vh",
            fontSize: "1.04vw"
        },
        copyright: {
            width: "auto",
            fontSize: "0.78vw",
            margin: "1.56vw auto 2.16vw auto"
        }
    }
}