import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputValidation } from "../../models/InputValidation";
import { validateEmail } from "../../helpers/Common";
import { ResponseModel } from "../../models/ResponseModel";
import { LostPasswordLoader } from "../../loaders/LostPasswordLoader";
import { Input } from "../Shared/Inputs/Input";
import { Button } from "../Shared/Button";
import { postLostPasswordRequest } from "../../services/TeacherService";

interface Props {
    styles: any;
    openAlert(status: string, message: string): void;
}

export const LostPassword: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [validation, setValidation] = useState<InputValidation>({});
    const [loading, setLoading] = useState<boolean>(false);
    // const [toErrorPage] = useErrorPage();
    const styles = props.styles;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value)
        if(validation?.invalid){
            validateInput(value);
        }        
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;   
        validateInput(value);
    }

    const validateInput = (value: string): boolean => {
        let isValidInput: boolean = false;
        if (value === "") {    
            setValidation({valid: false, invalid: true, invalidMessage: "Email is required!"});
        } 
        else if (!validateEmail(value)) {   
            setValidation({valid: false, invalid: true, invalidMessage: "Invalid email address!"});
        } 
        else {
            setValidation({valid: true, invalid: false});
            isValidInput = true;
        }     
        return isValidInput;
    }
    
    async function submitEmail(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();  
        let isValidInput = validation.valid;
        if(!isValidInput) {
            isValidInput = validateInput(email);
        }      
        if(isValidInput) {
            setLoading(true);
            await postLostPasswordRequest(email)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.Status) {            
                    navigate('/teacher/login');
                    props.openAlert("success", response.Message);
                }
                else {
                    props.openAlert("error", response.Message);
                }
            }) 
            .catch((error) => {            
                // toErrorPage(error);
            });
        }
        else {
            props.openAlert("error", "Invalid form inputs.");
        }
    }    
    
    return (
        <>
            {loading ? <LostPasswordLoader/> : 
            <form style={{...styles.passwordForm, ...styles.passwordForm.lost}} onSubmit={submitEmail}>
                <h5 style={styles.title}>Lost Your Password?</h5>
                <p style={styles.subTitle}>Please enter your email address.</p>
                <Input
                    label={'Email *'}
                    type={'text'}
                    name={"email"}
                    value={email}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation}                    
                />
                <Button type="submit" style={{...styles.submitButton, ...styles.resetButton}}>{"SEND ME THE LINK"}</Button>
            </form>}
        </>
    )
}