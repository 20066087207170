import React from "react";
import ContentLoader from "react-content-loader";
import { Color } from "../enums/Color";

interface Props {
    styles: any;
}

export const ImageSlideLoader: React.FC<Props> = (props) => {
    const styles = props.styles; 
    return (
        <div style={styles}>
            <div style={styles.contentWrapper}>
                <ContentLoader 
                    speed={2}
                    width={"100%"}
                    height={400}                
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}                    
                >
                    <rect x="0" y="0" rx="5" ry="5" width="60%" height="20"/> 
                    <rect x="0" y="40" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="60" rx="3" ry="3" width="95%" height="10"/>                 
                    <rect x="0" y="80" rx="3" ry="3" width="95%" height="10"/>
                    <rect x="0" y="100" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="120" rx="3" ry="3" width="90%" height="10"/>
                    <rect x="0" y="140" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="160" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="180" rx="3" ry="3" width="90%" height="10"/>
                    <rect x="0" y="200" rx="3" ry="3" width="80%" height="10"/>
                    <rect x="0" y="220" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="240" rx="3" ry="3" width="90%" height="10"/>
                    <rect x="0" y="260" rx="3" ry="3" width="90%" height="10"/>
                    <rect x="0" y="280" rx="3" ry="3" width="95%" height="10"/>
                    <rect x="0" y="300" rx="3" ry="3" width="95%" height="10"/>
                    <rect x="0" y="320" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="340" rx="3" ry="3" width="100%" height="10"/>
                    <rect x="0" y="360" rx="3" ry="3" width="90%" height="10"/>
                    <rect x="0" y="380" rx="3" ry="3" width="60%" height="10"/>
                </ContentLoader>
            </div>
            <div style={styles.imageWrapper}>
                <ContentLoader 
                    speed={2}
                    width={"100%"}
                    height={400}                
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}                    
                >
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="400"/>               
                </ContentLoader>
            </div>
        </div>
    )
}