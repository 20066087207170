import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../Shared/Button';
import { Input } from '../Shared/Inputs/Input';
import { ProfileValidation, Teacher } from '../../models/Teacher';
import { getTeacherProfile, postTeacherProfile } from '../../services/TeacherService';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { validateEmail, validatePhoneNumber } from '../../helpers/Common';
import { ResponseModel } from '../../models/ResponseModel';
import { ClassDescriptionLoader } from '../../loaders/ClassDescriptionLoader';
import { QuillEditor } from '../Shared/QuillEditor';
import { AuthContext } from '../../providers/AuthContextProvider';
import { ReactComponent as Dropdown } from "../../assets/svgs/drop_down.svg";
import { BankAccount } from '../../models/BankAccount';

interface Props {
    teacherId: number;
    openAlert(status: string, message: string): void;
}

const initialValidation: ProfileValidation = {
    FirstName: {},
    LastName: {}, 
    TepNo: {}, 
    WhatsappNo: {},        
    Email: {}
}

const initialBankAccount: BankAccount = {
    BankAccountId: 0,
    BankName: "",
    BranchName: "",
    AccountNumber: "",
    AccountHolderName: ""
}

export const Profile: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profilePage")); 
    const authContext = useContext(AuthContext);  
    const [teacher, setTeacher] = useState<Teacher>();
    const [bankAccount, setBankAccount] = useState<BankAccount>(initialBankAccount);
    const [validation, setValidation] = useState<ProfileValidation>(initialValidation);
    const fileInputRef = useRef<HTMLInputElement>(null);  
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(Texts.defaultAvatar);
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });   
    const [expandAccountSection, setExpandAccountSection] = useState<boolean>(false);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("profilePage"));        
    }, [isMobile]);

    useEffect(() => {    
        getProfile();        
    }, []);

    async function getProfile() {
        await getTeacherProfile(props.teacherId)
        .then((data: any) => {            
            setTeacher(data.Teacher);
            if(data.Teacher.Image !== "") {
                setImagePreviewUrl(data.Teacher.Image);
            } 
            if(data.BankAccount) {
                setBankAccount(data.BankAccount); 
            }                
            setLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setTeacher((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleAccountInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setBankAccount((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            setImagePreviewUrl(URL.createObjectURL(file));
        }
    };
    
    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "FirstName" || name === "LastName") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "TepNo") {            
            if (value !== "" && !validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid phone number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "WhatsappNo") {  
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Whatsapp number is required"}}));
            } 
            else if (!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }     
    }    

    const validateForm = (): number => {
        let formValidation: ProfileValidation = {
            FirstName: {valid: true},
            LastName: {valid: true},
            TepNo: {valid: true},
            WhatsappNo: {valid: true},
            Email: {valid: true}
        }        
        if(teacher!.FirstName === "") {
            formValidation.Forename = {valid: false, invalid: true, invalidMessage: "FirstName is required"};
        }
        if(teacher!.LastName === "") {
            formValidation.Surname = {valid: false, invalid: true, invalidMessage: "LastName is required"};
        }
        if (teacher!.TepNo !== "" && !validatePhoneNumber(teacher!.TepNo)) {
            formValidation.TepNo = {valid: false, invalid: true, invalidMessage: "Invalid phone number"};
        } 
        if(teacher!.WhatsappNo === "") {
            formValidation.TepNo = {valid: false, invalid: true, invalidMessage: "Whatsapp number is required"};
        } 
        else if (!validatePhoneNumber(teacher!.WhatsappNo)) {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"};
        }
        if(teacher!.Email === "") {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if (!validateEmail(teacher!.Email)) {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        setValidation(formValidation); 
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key]!.valid).length;
        return validInputCount;       
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {        
        event.preventDefault();     
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;        
        if(validInputCount < 5) {
            validInputCount = validateForm();
        }
        if(validInputCount === 5) {               
            setLoading(true);   
            const teacherString = JSON.stringify(teacher);
            const bankAccountString = JSON.stringify(bankAccount);
            const formData = new FormData();
            formData.append('teacher', teacherString);  
            formData.append('profileImage', selectedImage!);
            formData.append('bankAccount', bankAccountString);
            await postTeacherProfile(formData)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.Status) {
                    props.openAlert("success", response.Message);
                    authContext.setLoggedTeacher(response.Data);                                      
                }
                else {
                    props.openAlert("error", response.Message);
                }
            })
            .catch((error) => { 
                setLoading(false);           
                //toErrorPage(error);
            });
        }
    }

    const toggleAccountSection = () => {
        setExpandAccountSection(!expandAccountSection);
    }

    return (
        <>
            {loading ? <ClassDescriptionLoader/> :
            <form onSubmit={handleSubmit}>                        
                <div style={styles.imageWrapper}>
                    <img src={imagePreviewUrl} alt="Profile Image" onClick={handleImageClick} style={styles.image}/>
                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                </div> 
                <div style={{...styles.inputWrapper, ...styles.rowWrapper}}>
                    <div style={styles.halfWrapper}>
                        <label style={styles.label}>{"First Name *"}</label>          
                        <Input 
                            type="text" 
                            name={"FirstName"} 
                            value={teacher!.FirstName}
                            styles={styles.input}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.FirstName} 
                        />
                    </div>
                    <div style={styles.halfWrapper}>
                        <label style={styles.label}>{"Last Name *"}</label>
                        <Input 
                            type="text" 
                            name={"LastName"} 
                            value={teacher!.LastName}
                            styles={styles.input}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.LastName} 
                        />    
                    </div>
                </div>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Phone No"}</label>                        
                    <Input 
                        type="text" 
                        name={"TepNo"} 
                        value={teacher!.TepNo} 
                        styles={styles.input}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        validation={validation.TepNo} 
                    />
                </div>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Whatsapp No *"}</label>
                <Input 
                    type="text" 
                    name={"WhatsappNo"} 
                    value={teacher!.WhatsappNo}
                    styles={styles.input}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.WhatsappNo} 
                />
                </div>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Email *"}</label>
                    <Input 
                        type="text" 
                        name={"Email"} 
                        value={teacher!.Email} 
                        styles={styles.input}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        validation={validation.Email} 
                    />
                </div>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Description"}</label>
                    <QuillEditor
                        value={teacher!.Description} 
                        onChange={handleInputChange}
                        styles={styles.editor}
                    /> 
                </div> 
                <div style={styles.bankLabel} onClick={toggleAccountSection}>
                    <div>{"Bank Account Details"}</div>
                    <Dropdown/>
                </div>              
                {expandAccountSection && <>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"Bank Name"}</label>
                        <Input 
                            type="text" 
                            name={"BankName"} 
                            value={bankAccount.BankName} 
                            styles={styles.input}
                            onChange={handleAccountInputChange}
                            validation={validation.Email} 
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"Branch Name"}</label>
                        <Input 
                            type="text" 
                            name={"BranchName"} 
                            value={bankAccount.BranchName} 
                            styles={styles.input}
                            onChange={handleAccountInputChange}
                            validation={validation.Email} 
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"Account Number"}</label>
                        <Input 
                            type="text" 
                            name={"AccountNumber"} 
                            value={bankAccount.AccountNumber} 
                            styles={styles.input}
                            onChange={handleAccountInputChange}
                            validation={validation.Email} 
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"Account Holder Name"}</label>
                        <Input 
                            type="text" 
                            name={"AccountHolderName"} 
                            value={bankAccount.AccountHolderName} 
                            styles={styles.input}
                            onChange={handleAccountInputChange}
                            validation={validation.Email} 
                        />
                    </div>
                </>}
                <Button style={styles.submitButton} onClick={handleSubmit}>Submit</Button>                        
            </form>}
        </>
    )
}