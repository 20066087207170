import Axios from "axios";
import { CommonTexts as Texts } from "../helpers/LayoutTexts";
import { TeacherSearchResponseModel } from "../models/TeacherSearchResponseModel";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import { LoginModel } from "../models/LoginModel";
import { RegisterModel } from "../models/RegisterModel";
import { EmailPasswordModel } from "../models/EmailPasswordModel";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function postTeacherLogin(loginModel: LoginModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/login", loginModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postTeacherLogout(teacherId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/logout?teacherId=" + teacherId, {}, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postTeacherRegistration(registerModel: RegisterModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/register", registerModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postLinkedInAuthCode(authCode: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/authenticateWithLinkedIn?authCode=" + authCode)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function postLostPasswordRequest(email: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .post(Texts.apiBaseUrl + "teachers/lostPassword?email=" + email, {})
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getResetPasswordValidity(email: string, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(Texts.apiBaseUrl + "teachers/resetPasswordValidity", {            
            params: {      
                email: email,
                token: token
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postResetPassword(passwordModel: EmailPasswordModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "teachers/resetPassword", passwordModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getAdRequestModel(gradeCategoryIds?: string, districtIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adRequestModel", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",
                districtIds: districtIds ? districtIds : "",
                languageCode: localStorage.getItem('languageCode')!
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postAdSearchModel(responseModel: TeacherSearchResponseModel) {
    responseModel.LanguageCode = localStorage.getItem('languageCode')!;
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adSearchModel", responseModel, {                                
            cache: {    
                methods: ["post"],            
                ttl: 1000 * 60 * 5 // 5 min
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getGradeCategorySubjects(gradeCategoryIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/gradeCategorySubjects", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",
                languageCode: localStorage.getItem('languageCode')!
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDistrictTowns(districtIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/districtTowns", {            
            params: {
                districtIds: districtIds ? districtIds : "",
                languageCode: localStorage.getItem('languageCode')!
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postAdResponseModel(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adResponse", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postAdEditModel(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adEditResponse", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAds(teacherId: number, status: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/teacherAds", {            
            params: {
                teacherId: teacherId,
                status: status,
                languageCode: localStorage.getItem('languageCode')!
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAdDetails(teacherAdId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adWithDetails", {            
            params: {
                teacherAdId: teacherAdId,
                languageCode: localStorage.getItem('languageCode')!
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherProfile(teacherId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/profile", {            
            params: {
                teacherId: teacherId
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postTeacherProfile(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/profile", formData)
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteTeacherAd(deleteAdId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .delete(Texts.apiBaseUrl + "teachers/deleteAd", {            
            params: {
                deleteAdId: deleteAdId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function validatePromoCode(teacherId: number, promoCode: string | undefined, paymentTypeId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .get(Texts.apiBaseUrl + "teachers/promoCodeValidation", {            
            params: {      
                teacherId: teacherId, 
                promoCode: promoCode, 
                paymentTypeId: paymentTypeId
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getTeacherFeaturedAds() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/featuredAds", {                      
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function validateCashCode(paymentId: number, cashCode: string, whatsappNo: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/validateCashCode?paymentId=" + paymentId + "&cashCode=" + cashCode + "&whatsappNo=" + whatsappNo)
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAdsCount(teacherId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adsCountByTeacher", {            
            params: {      
                teacherId: teacherId
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}