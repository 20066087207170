import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const profilePage = {
    mobile: {
        container: {
            marginTop: "50px"
        },
        imageWrapper: {
            margin: "20px 0 42px 0"
        },
        image: {
            height: "auto",
            width: "-webkit-fill-available",
            objectFit: "cover",            
            backgroundColor: Color.LightAsh
        },
        detailContainer: {
            margin: "20px 5%"
        },
        name: {
            fontSize: "16px",
            fontWeight: "bold",
            margin: "10px 0 10px 0"
        },
        tepNo: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            flexWrap: "wrap-reverse",
            fontSize: "16px",
            marginTop: "8px",
            cursor: "pointer",
            icon: {
                height: "18px",
                width: "18px",
                marginLeft: "8px",
                fill: Color.Green
            }            
        },
        description: {
            marginTop: "10px"
        },
        inputWrapper: {
            marginBottom: "4.83vw"
        },
        rowWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        halfWrapper: {
            width: "48%"
        },
        label: {
            fontSize: "3.86vw",
            fontFamily: Font.Poppins,
            color: Color.DeepAsh,
            margin: "0 0 1.21vw 3.62vw"
        },
        input: {                       
            wrapper: {
                margin: "auto",
                alignItems: "center"
            },               
            input: { 
                height: "12.08vw",
                padding: "0 3.62vw",                    
                fontSize: "3.86vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                borderBottom: "1px solid " + Color.PhilippineGray,
                valid: {
                    backgroundColor: Color.AliceBlue,
                    border: "1px solid " + Color.Blue,
                    borderBottom: "1px solid " + Color.Blue
                },
                invalid: {
                    backgroundColor: Color.CoralRed,
                    border: "1px solid " + Color.Red,
                    borderBottom: "1px solid " + Color.Red,
                    color: Color.Red
                }
            }
        },
        editor: {
            height: "unset",
            minHeight: "60.86vw",            
            border: "0.24vw solid" + Color.MiddleAsh,
            borderRadius: "1.21vw"
        },
        bankLabel: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "0 3.62vw 4.83vw 3.62vw",
            fontWeight: "bold",
            cursor: "pointer",
            justifyContent: "space-between"
        },
        submitButton: {
            height: "50px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px 0"
        }
    },
    desktop: {
        container: {
            margin: "60px 0 20px 0",
            paddingLeft: "32%",
            paddingRight: "32%"
        },
        imageWrapper: {
            margin: "48px 0 42px 0"
        },
        image: {            
            width: "60%",
            margin: "0 20%",
            borderRadius: "0.41vw"
        },
        inputWrapper: {
            marginBottom: "1.56vw"
        },
        label: {
            fontSize: "0.94vw",
            margin: "0 0 0.52vw 0.52vw"
        },
        input: {              
            input: { 
                height: "3.64vw",
                padding: "0 1.56vw",
                fontSize: "0.83vw",
                borderRadius: "0.52vw"
            }
        },
        editor: {
            minHeight: "22.76vw",
            width: "-webkit-fill-available",
            border: "0.05vw solid" + Color.MiddleAsh,
            borderRadius: "0.52vw"
        },
        bankLabel: {
            margin: "0 0.52vw 1.56vw 0.52vw"
        }
    }
}