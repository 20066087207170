import { ZoomMtg } from "@zoom/meetingsdk";
import { useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedStudent } from "../../models/LoggedStudent";
import { getZoomSDKSignature } from "../../services/ClassService";
import { ResponseModel } from "../../models/ResponseModel";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import LogoLoader from "../../loaders/LogoLoader";
import { validateStudentLogin } from "../../services/StudentService";
import { useAlert } from "../../hooks/useAlert";
import { useMediaQuery } from "react-responsive";
import useErrorPage from "../../hooks/useErrorPage";
import { UserType } from "../../enums/UserType";

interface StateProps {
    week: OnlineClassWeek;
}

export const ZoomStreamingPage: React.FC = () => {
    const navigate = useNavigate();  
    const location = useLocation();
    const state = location.state as StateProps;
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("studentClassPage")); 
    const authContext = useContext(AuthContext);
    const student: LoggedStudent | null = authContext.getLoggedStudent();
    const week = state ? state.week : undefined;
    const [loading, setLoading] = useState<boolean>(true); 
    const [unauthorized, setUnauthorized] = useState<boolean>(false); 
    const {Alert, openAlert} = useAlert();
    const [toErrorPage] = useErrorPage();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 

    useEffect(() => {
        if(!week) {
            setLoading(false);
            setUnauthorized(true);
        }
        else if(student === null) {
            const searchParams = { callbackUrl: "/student/class-weeks/" + week.OnlineClassId, message: "Please login or register as student to access the class streaming page." };
            navigate({
                pathname: '/student/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
        else {
            getSDKSignature();
        }
        window.addEventListener("popstate", function handlePopState() {                             
            endMeeting();
            window.removeEventListener("popstate", handlePopState, true);
        }, true); 
        const interval = setInterval(validateUserLogin, 300000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("studentClassPage"));        
    }, [isMobile]);

    async function getSDKSignature() {                
        await getZoomSDKSignature(student!.StudentId, week!.OnlineClassId, week!.OnlineClassWeekId, student!.Token)
        .then((response: ResponseModel) => {      
            if(response.Status) {
                const meetingData = response.Data;
                let meetingNo: string = meetingData.MeetingNo.slice(0, -5);
                meetingNo = meetingNo.split('').reverse().join('');
                const password: string = meetingData.Password.split('').reverse().join('');                
                startMeeting(meetingNo, password, meetingData.SDKKey, meetingData.Signature);
            } 
            else {
                setLoading(false);
                setUnauthorized(true);
            }                 
        })
        .catch((error) => {
            toErrorPage(error, UserType.Student);
        });                            
    }

    async function validateUserLogin() {
        await validateStudentLogin(student!.StudentId, student!.Token)
        .then((isValidLogin: boolean) => {
            if(!isValidLogin) {
                localStorage.removeItem("student");
                localStorage.removeItem("token");   
                endMeeting();                   
            }
        })
        .catch((error) => {
            toErrorPage(error, UserType.Student);
        });
    }

    const startMeeting = (meetingNo: string, password: string, sdkKey: string, signature: string) => {
        // loads WebAssembly assets
        setLoading(false);
        startFullScreen();
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        document.getElementById("zmmtg-root")!.style.display = "block";    
        ZoomMtg.init({
            leaveUrl: "/student/class-weeks/" + week!.OnlineClassId,
            patchJsMedia: true,
            leaveOnPageUnload: true,
            success: (success: unknown) => {                                
                ZoomMtg.join({
                    signature: signature,
                    sdkKey: sdkKey,
                    meetingNumber: meetingNo,
                    passWord: password,
                    userName: student!.FirstName + " " + student!.LastName,
                    userEmail: student!.Email,
                    success: (success: unknown) => {
                        openAlert("success", "Join meeting successfully.");
                    },
                    error: (error: unknown) => {
                        openAlert("error", "Failed to join the meeting.");
                    }
                });
            },
            error: (error: unknown) => {
                // toErrorPage(error);
            }
        });
    }

    const endMeeting = () => {
        ZoomMtg.leaveMeeting({
            success: (success: unknown) => {
                openAlert("success", "Meeting ended successfully.");
            },
            error: (error: unknown) => {
                openAlert("error", "Failed to end the meeting.");
            }
        });
    }

    const startFullScreen = () => {
        const element = document.documentElement as any;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) { // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE11
            element.msRequestFullscreen();
        }
    };
      
    return (
        <>
            {loading && <LogoLoader />}
            {unauthorized && <div style={styles.streaming.unauthorized}>Unauthorized Access</div>}   
            <Alert />         
        </>
    );
}