import React from 'react';
import { LoggedTeacher } from '../models/LoggedTeacher';
import { LoggedStudent } from '../models/LoggedStudent';
import CryptoJS from 'crypto-js';

interface Props {
    children: React.ReactNode;
}

interface AuthContextType {   
    setLoggedTeacher(loggedTeacher: any, token?: string): void;
    getLoggedTeacher(): any;
    setLoggedStudent(loggedStudent: any, token?: string): void;
    getLoggedStudent(): any;    
}

export const AuthContext = React.createContext<AuthContextType>({            
    setLoggedTeacher: () => {},
    getLoggedTeacher: () => {},
    setLoggedStudent: () => {},
    getLoggedStudent: () => {}
});

export const AuthContextProvider: React.FC<Props> = (props) => {             

    const setTeacher = (loggedTeacher: any, token?: string) => {
        const teacherString = JSON.stringify(loggedTeacher);
        const encrypedTeacherString = CryptoJS.AES.encrypt(teacherString, "").toString();
        localStorage.setItem("teacher", encrypedTeacherString);
        if(token) {
            localStorage.setItem("token", token);
        }
    }

    const getTeacher = (): LoggedTeacher | null => {           
        const teacherString = localStorage.getItem("teacher");          
        if(teacherString !== null) {  
            const decryptedTeacherString = CryptoJS.AES.decrypt(teacherString, "").toString(CryptoJS.enc.Utf8);
            const teacher: LoggedTeacher = JSON.parse(decryptedTeacherString);
            teacher.Token = localStorage.getItem("token")!;
            return teacher;
        } 
        else {
            return null;
        }       
    }

    const setStudent = (loggedStudent: any, token?: string) => {        
        const studentString = JSON.stringify(loggedStudent);        
        const encrypedStudentString = CryptoJS.AES.encrypt(studentString, "").toString();        
        localStorage.setItem("student", encrypedStudentString);
        if(token) {
            localStorage.setItem("token", token);
        }
    }

    const getStudent = (): LoggedStudent | null => {           
        const studentString = localStorage.getItem("student");          
        if(studentString !== null) {  
            const decryptedStudentString = CryptoJS.AES.decrypt(studentString, "").toString(CryptoJS.enc.Utf8);
            const student: LoggedStudent = JSON.parse(decryptedStudentString);
            student.Token = localStorage.getItem("token")!;
            return student;
        } 
        else {
            return null;
        } 
    }

    return (
        <AuthContext.Provider value={{     
            setLoggedTeacher: setTeacher,                   
            getLoggedTeacher: getTeacher,
            setLoggedStudent: setStudent,
            getLoggedStudent: getStudent            
        }}>           
            {props.children}
        </AuthContext.Provider>
    );
}