import { useContext, useEffect, useState } from "react";
import { EmailPasswordModel, EmailPasswordValidation } from "../../models/EmailPasswordModel";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContextProvider";
import { getResetPasswordValidity, postResetPassword } from "../../services/StudentService";
import { ResponseModel } from "../../models/ResponseModel";
import { ResetPasswordLoader } from "../../loaders/ResetPasswordLoader";
import { Input } from "../Shared/Inputs/Input";
import { Button } from "../Shared/Button";

interface Props {
    styles: any;  
    email: string;
    token: string;
    openAlert(status: string, message: string): void;
}

const initialPasswordValidation: EmailPasswordValidation = {
    newPassword: {},
    confirmNewPassword: {}
}

export const ResetPassword: React.FC<Props> = (props) => {
    const styles = props.styles;
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const intialFormInputs: EmailPasswordModel = {
        email: props.email,
        token: props.token,        
        newPassword: "",  
        confirmNewPassword: ""
    };
    const [passwordModel, setPasswordModel] = useState<EmailPasswordModel>(intialFormInputs);
    const [validation, setValidation] = useState<EmailPasswordValidation>(initialPasswordValidation);
    // const [toErrorPage] = useErrorPage();

    useEffect(() => {         
        validateRequest();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setPasswordModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {
        if(name === "newPassword") {    
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "confirmNewPassword") {
            const password = passwordModel.newPassword;      
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}}));
            } 
            else if (password !== value) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }        
    }    

    const validateForm = (): number => {
        let formValidation: EmailPasswordValidation = {
            newPassword: {valid: true},
            confirmNewPassword: {valid: true}
        }
        if(passwordModel.newPassword === ""){
            formValidation.newPassword = {valid: false, invalid: true, invalidMessage: "Password is required"}
        }
        if(passwordModel.confirmNewPassword === ""){
            formValidation.confirmNewPassword = {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}
        }
        else if (passwordModel.newPassword !== passwordModel.confirmNewPassword) {
            formValidation.confirmNewPassword = {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"};
        }
        setValidation(formValidation);
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;
    }

    async function validateRequest() {
        setLoading(true);
        await getResetPasswordValidity(props.email, props.token)
        .then((response: ResponseModel) => {
            setLoading(false);
            if(!response.Status) {
                navigate('/student/login');
                props.openAlert("error", response.Message);
            }
        })      
        .catch((error) => {            
            // toErrorPage(error);
        });
    }

    async function handleSubmitClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;           
        if(validInputCount < 2) {                        
            validInputCount = validateForm();            
        }     
        if(validInputCount === 2) {
            setLoading(true);
            await postResetPassword(passwordModel)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.Status) {
                    authContext.setLoggedStudent(response.Data, response.Token);            
                    navigate('/');            
                }
                else {
                    props.openAlert("error", response.Message);
                }
            })
            .catch((error) => {            
                // toErrorPage(error);
            });
        }
        else {
            props.openAlert("error", "Invalid form inputs.");
        }
    }
    
    return (
        <>
            {loading ? <ResetPasswordLoader/> : 
            <form style={{...styles.passwordForm, ...styles.passwordForm.reset}} onSubmit={handleSubmitClick}>
                <h5 style={styles.title}>Change Password</h5>
                <p style={{...styles.subTitle, ...styles.subTitle.reset}}>Please choose a new password.</p>
                <Input
                    label={'New Password *'}
                    type={'password'}
                    name={"newPassword"}
                    value={passwordModel.newPassword}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.newPassword}
                />
                <Input
                    label={'Confirm New Password *'}
                    type={'password'}
                    name={"confirmNewPassword"}
                    value={passwordModel.confirmNewPassword}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.confirmNewPassword}
                />
                <Button type="submit" disabled={loading} style={{...styles.submitButton, ...styles.resetButton}}>{"RESET PASSWORD"}</Button>
            </form>}
        </>
    )
}