import { useContext, useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedStudent } from "../../models/LoggedStudent";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import { Localization } from "../../models/Localization";
import { useMediaQuery } from "react-responsive";
import { getPageLocalizations } from "../../services/CommonService";
import { downloadTuteByStudent, getClassWeeksByStudent, getWeekPayModel } from "../../services/ClassService";
import { ToolBar } from "../../components/Shared/ToolBar";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { CardListLoader } from "../../loaders/CardListLoader";
import { NoData } from "../../components/Shared/NoData";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/svgs/clock.svg";
import { ReactComponent as Cash} from "../../assets/svgs/cash.svg";
import { ReactComponent as Files } from "../../assets/svgs/files.svg";
import { ReactComponent as Download } from "../../assets/svgs/download.svg";
import { getFormatedDate, getFormatedTime } from "../../helpers/Common";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { Button } from "../../components/Shared/Button";
import { WebXPayModel } from "../../models/WebXPayModel";
import { PopUpDialog } from "../../components/Shared/Dialogs/PopUpDialog";
import $ from 'jquery'

const monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];

export const StudentClassWeekPage: React.FC = () => {  
    let params = useParams();   
    const navigate = useNavigate();  
    const location = useLocation();
    const classId: number = +params.classId!;  
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("studentClassPage")); 
    const authContext = useContext(AuthContext);
    const student: LoggedStudent | null = authContext.getLoggedStudent();
    const [classWeeks, setClassWeeks] = useState<OnlineClassWeek[]>([]); 
    const [expandIndex, setExpandIndex] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(true);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });        
    const bannerType = isMobile ? 7 : 8;   
    const scrollElementRef = useRef<any>();    
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedWeek, setSelectedWeek] = useState<OnlineClassWeek>();    

    useEffect(() => {
        if(student !== null) {
            getLocalizations();
            getWeekList();                               
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login or register as student to access the class week page." };
            navigate({
                pathname: '/student/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("studentClassPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 38)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getWeekList() {                  
        await getClassWeeksByStudent(classId, student!.StudentId)
        .then((weekList: OnlineClassWeek[]) => {
            setClassWeeks(weekList);  
            let activeIndex = weekList.findIndex(x => !x.IsExpired);  
            setExpandIndex(activeIndex);           
            setLoading(false);
            scrollElementRef.current.scrollIntoView();
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    const openPayDialog = (week: OnlineClassWeek) => {
        setSelectedWeek(week);
        setOpenDialog(true);
    }

    async function payClassFee (isOnlinePayment: boolean) {
        setOpenDialog(false);
        setLoading(true);
        await getWeekPayModel(selectedWeek!.OnlineClassId, selectedWeek!.OnlineClassWeekId, student!.StudentId, selectedWeek!.ClassFee!, isOnlinePayment)
        .then((payModel: any) => { 
            setLoading(false);                         
            if(isOnlinePayment) {     
                const webXPayModel: WebXPayModel = payModel;
                $('.pay').append('<form action="' + webXPayModel.PaymentUrl + '" id="form" name="form" method="post" style="display:none;">' +
                    '<input type="text" name="first_name" value="' + webXPayModel.FirstName + '" />' +
                    '<input type="text" name="last_name" value="' + webXPayModel.LastName + '" />' +
                    '<input type="text" name="email" value="' + webXPayModel.Email + '" />' +
                    '<input type="text" name="contact_number" value="' + webXPayModel.ContactNumber + '" />' +
                    '<input type="text" name="address_line_one" value="' + webXPayModel.Address + '" />' +
                    '<input type="text" name="process_currency" value="' + webXPayModel.Currency + '" />' +
                    '<input type="text" name="secret_key" value="' + webXPayModel.SecretKey + '" />' +
                    '<input type="text" name="custom_fields" value="' + webXPayModel.CustomFields + '" />' +
                    '<input type="text" name="payment" value="' + webXPayModel.PaymentStr + '" />' +
                '</form>');            
                var paymentForm = document.getElementById("form") as HTMLFormElement;                
                paymentForm.submit();
            }
            else {
                navigate('/bank-details', {
                    state: { price: selectedWeek!.ClassFee! }
                })
            }            
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    const joinClass = (week: OnlineClassWeek) => {
        navigate('/online-class/streaming', {
            state: { week: week }
        });
    }
    
    const downloadTute = (weekId: number, tuteName: string) => {
        downloadTuteByStudent(weekId, tuteName, student!.StudentId, student!.Token);
    }

    const getMonthWeek = (dateStr: string): string => {
        const date = new Date(dateStr);
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const weekNumber = Math.ceil(day / 7);
        return `${month} Week ${weekNumber}`;
    }

    return (
        <>
            <ToolBar>{"Online Class Weeks"}</ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{"Online Class Weeks"}</h1>            
                    <div style={styles.listContainer}>
                        {loading ? <CardListLoader/> : <>
                            {classWeeks.length === 0 ? <NoData/> : <>                                               
                                {classWeeks.map((week, index) =>
                                    <div 
                                        key={index} 
                                        style={{...styles.card, ...((week.IsCompleted || week.IsExpired) ? styles.card.expired : styles.card.active)}}
                                        onClick={() => setExpandIndex(index)}   
                                    >                                        
                                        <div style={styles.card.weekNo}>{getMonthWeek(week.Date!)}</div>                                                                                                  
                                        <div style={styles.card.title}>
                                            {week.Title}
                                        </div>                                   
                                        <div style={styles.card.dateWrapper}>
                                            <div style={styles.card.rowWrapper}>
                                                <CalendarIcon style={styles.card.calendarIcon}/>{getFormatedDate(week.Date!)}
                                            </div>
                                            <div style={styles.card.rowWrapper}>
                                                <ClockIcon style={styles.card.clockIcon}/>{getFormatedTime(week.Time!)}
                                            </div>                                    
                                        </div> 
                                        {expandIndex === index && <>
                                            <div style={styles.card.fee}>
                                                <Cash style={styles.card.cashIcon}/>{week.PaymentRequired ? week.ClassFee + " LKR" : "Free"}
                                            </div>
                                            {week.HasTute && <div style={styles.card.tute}>
                                                <Files style={styles.card.fileIcon}/>
                                                {week.TuteName}
                                                {week.IsPaid && <Download style={styles.card.downloadIcon} onClick={() => downloadTute(week.OnlineClassWeekId, week.TuteName!)}/>}
                                            </div>}
                                            <div style={styles.card.description}>
                                                <div dangerouslySetInnerHTML={{ __html: week.Description! }}/>
                                            </div> 
                                            {!(week.IsCompleted || week.IsExpired) && <>
                                                {(!week.PaymentRequired || week.IsPaid) ? <Button style={{...styles.card.actionButton, ...styles.card.actionButton.attend}} onClick={() => joinClass(week)}>
                                                    {"Join Class"}                        
                                                </Button> : 
                                                <Button style={{...styles.card.actionButton, ...styles.card.actionButton.pay}} onClick={() => openPayDialog(week)}>
                                                    {"Pay " + week.ClassFee + " LKR"}                        
                                                </Button>}
                                            </>}                                            
                                        </>}                                                                                                               
                                    </div>
                                )}                                                                
                            </>}
                        </>}
                        <div ref={scrollElementRef}></div>
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div> 
            <div className="pay"></div>           
            {openDialog && <PopUpDialog styles={styles.paymentDialog} toggle={() => setOpenDialog(!openDialog)}>
                {selectedWeek!.IsMonthlyPayment ? <p style={styles.paymentMessage}>{localizations.find(({ ElementName }) => ElementName === 'vwMonthlyPaymentMessage')?.ElementValue!.replace("@fee", selectedWeek!.ClassFee!.toString())}</p>                
                : <p style={styles.paymentMessage}>{localizations.find(({ ElementName }) => ElementName === 'vwWeeklyPaymentMessage')?.ElementValue!.replace("@fee", selectedWeek!.ClassFee!.toString())}</p>}                
                <div style={styles.payButtonWrapper}>
                    <Button style={styles.onlinePayButton} type="button" onClick={() => payClassFee(true)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwOnlinePayButton')?.ElementValue!}
                    </Button>                    
                    <Button style={styles.bankPayButton} type="button" onClick={() => payClassFee(false)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwBankPayButton')?.ElementValue!}
                    </Button>
                </div>
            </PopUpDialog>}
        </>        
    );
}