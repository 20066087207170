import { Color } from "../enums/Color";

export const pageLayout = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",             
            padding: "0 5%"            
        }
    },
    desktop: {        
        desktopTitle: {
            display: "block",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            zIndex: 1
        },
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        }
    }
}