import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { CardListLoader } from "../../loaders/CardListLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { NoData } from "../../components/Shared/NoData";
import { OnlineClass } from "../../models/OnlineClass";
import { deleteOnlineClass, getClassesByTeacher } from "../../services/ClassService";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Button } from "../../components/Shared/Button";
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as BinIcon } from "../../assets/svgs/bin.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar.svg";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { PopUpDialog } from "../../components/Shared/Dialogs/PopUpDialog";
import { useAlert } from "../../hooks/useAlert";
import { LoggedTeacher } from "../../models/LoggedTeacher";

export const TeacherClassPage: React.FC = () => {    
    const navigate = useNavigate();  
    const location = useLocation();  
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassPage")); 
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();
    const [onlineClasses, setOnlineClasses] = useState<OnlineClass[]>([]);    
    const [loading, setLoading] = useState<boolean>(true);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });                            
    const subjectCharacterLength: number = isMobile ? 42 : 84;   
    const gradeCharacterLength: number = isMobile ? 42 : 84;
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>();
    const {Alert, openAlert} = useAlert();
    const page: number = 1;
    const pageSize: number = 100;   
    const bannerType = isMobile ? 7 : 8;       

    useEffect(() => {    
        if(teacher !== null) {
            getLocalizations();
            getClassList();                               
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login or register to access the class page." };
            navigate({
                pathname: '/teacher/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("onlineClassPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 36)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getClassList() {                  
        await getClassesByTeacher(page, pageSize, teacher!.TeacherId)
        .then((onlineClassList: OnlineClass[]) => {
            setOnlineClasses(onlineClassList);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    async function deleteClass(classId: number) {                  
        await deleteOnlineClass(classId)
        .then((success: boolean) => {  
            setOpenDialog(false);
            if(success) {
                openAlert("success", "Online class has been deleted successfully.");  
                getClassList();                                                       
            }
            else {
                openAlert("error", "Oops! something went wrong.");
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        });
    }
    
    const toManageClass = (classId: number) => {        
        navigate("/teacher/class-manage/" + classId);        
    }

    const toClassWeeks = (classId: number) => {
        navigate("/teacher/class-weeks/" + classId);
    }
  
    const openDeleteWarning = (classId: number, subjects?: string) => {
        let content = <>
            <h1 style={styles.dialog.title}>{"Are you sure?"}</h1>
            <p style={styles.dialog.message}>{`Online class for ${subjects} will be deleted permanently.`}</p>
            <div className="center-content" style={styles.dialog.buttons}>
                <Button style={{...styles.dialog.button, ...styles.dialog.button.blue}} onClick={closeDialog}>{"NO"}</Button>                
                <Button style={{...styles.dialog.button, ...styles.dialog.button.red}} onClick={() => deleteClass(classId)}>{"YES"}</Button>
            </div>
        </>        
        setDialogContent(content);        
        setOpenDialog(true);
    }
       
    const closeDialog = () => {
        setOpenDialog(false);
    }

    return (
        <>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>                                   
                    <Button style={{...styles.classButton, ...styles.classButton.noMargin}} onClick={() => toManageClass(0)}>{"New Class"}</Button>
                    <div style={styles.class.container}>
                        {loading ? <CardListLoader/> : <>
                            {onlineClasses.length === 0 ? <NoData/> : <>                                               
                                {onlineClasses.map((onlineClass, index) =>
                                    <div key={index} style={styles.class.card}>                                
                                        <div style={styles.class.imageWrapper}>
                                            <img src={onlineClass.ClassImageUrl} alt="ad image" style={styles.class.image}></img>
                                        </div>
                                        <div style={styles.class.detailWrapper}>                                    
                                            {onlineClass.Subjects && <div style={styles.class.subjects}>
                                                {onlineClass.Subjects.length > subjectCharacterLength ? onlineClass.Subjects.slice(0, subjectCharacterLength) + " ..." : onlineClass.Subjects}
                                            </div>}                                    
                                            {onlineClass.Grades && <div style={styles.class.grades}>
                                                {onlineClass.Grades.length > gradeCharacterLength ? onlineClass.Grades.slice(0, gradeCharacterLength) + " ..." : onlineClass.Grades}
                                            </div>} 
                                            <div style={styles.class.buttonWrapper}>
                                                <Button style={styles.class.editButton} onClick={() => toManageClass(onlineClass.OnlineClassId)}>
                                                    {isMobile ? <EditIcon style={styles.class.editButton.icon} /> : "Edit"}
                                                </Button>                                        
                                                <Button style={styles.class.deleteButton} onClick={() => openDeleteWarning(onlineClass.OnlineClassId, onlineClass.Subjects)}>
                                                    {isMobile ? <BinIcon style={styles.class.deleteButton.icon} /> : "Delete"}
                                                </Button>
                                                <Button style={styles.class.weekButton} onClick={() => toClassWeeks(onlineClass.OnlineClassId)}>
                                                    <CalendarIcon style={styles.class.weekButton.icon} />{" Weeks"}
                                                </Button>
                                            </div>                          
                                        </div>
                                    </div>
                                )}                        
                            </>}
                        </>}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
            {openDialog && <PopUpDialog styles={styles.dialog.popUp} toggle={closeDialog} closeIcon={true}>
                {dialogContent}
            </PopUpDialog>} 
            <Alert/>           
        </>
    )
}