import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedTeacher } from "../../models/LoggedTeacher";
import { LoggedStudent } from "../../models/LoggedStudent";
import { useAlert } from "../../hooks/useAlert";
import { useMediaQuery } from "react-responsive";
import { postTeacherLogout } from "../../services/TeacherService";
import { ResponseModel } from "../../models/ResponseModel";
import { postLinkedInAuthCode } from "../../services/StudentService";
import { Helmet } from "react-helmet-async";
import { Footer } from "../../components/Shared/Footer";
import { Transition } from "../../components/Shared/Transition";
import { Login } from "../../components/StudentLogin/Login";
import { Register } from "../../components/StudentLogin/Register";
import { LostPassword } from "../../components/StudentLogin/LostPassword";
import { ResetPassword } from "../../components/StudentLogin/ResetPassword";
import { NewHeader } from "../../components/Shared/NewHeader";

export const StudentLoginPage: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const stage = params.stage;
    const [searchParams] = useSearchParams();
    const callbackUrl: string | null = searchParams.get("callbackUrl");
    const message: string | null = searchParams.get("message");
    const linkedInAuthCode: string | null = searchParams.get("code");
    const linkedInError: string | null = searchParams.get("error_description");
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("studentLoginPage"));
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();
    const student: LoggedStudent | null = authContext.getLoggedStudent();
    const {Alert, openAlert} = useAlert();
    // const [toErrorPage] = useErrorPage();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("studentLoginPage"));
    }, [isMobile]);
    
    useEffect(() => {    
        if(student !== null) {        
            navigate("/");
        } 
        if(teacher !== null) {        
            localStorage.removeItem("teacher");
            localStorage.removeItem("token");
            postTeacherLogout(teacher.TeacherId, teacher.Token);
        }   
        if (linkedInAuthCode !== null && linkedInAuthCode.trim() !== "") {
            handlePostLinkedInAuthCode(linkedInAuthCode);
        }
        else if (linkedInError !== null && linkedInError !== "") {
            openAlert("error", linkedInError);
        }
    }, []);
    
    // This is to send the linkedin code to API at the first load. if the code is exist in query parameter
    async function handlePostLinkedInAuthCode(accessToken: string) {
        await postLinkedInAuthCode(accessToken)
        .then((response: ResponseModel) => {
            if (response.Status) {                
                authContext.setLoggedStudent(response.Data, response.Token);
                navigate("/");                
            }
            else {
                openAlert("error", response.Message);
            }
        })
        .catch((error) => {
            // toErrorPage(error);
        });
    }
        
    const handleChangeState = (stage: string) => {
        if(callbackUrl !== null) {
            searchParams.set("callbackUrl", callbackUrl);
        }
        if(message !== null) {
            searchParams.set("message", message);
        }
        navigate("/student/" + stage + `?${searchParams}`);
    }
    
    return (
        <>
            <Helmet><title>Student Account - TuteClass</title></Helmet>            
            <NewHeader disableStudentLogin={true} />
            <div style={styles.mainWrapper}>
                <div style={styles.leftWrapper}></div>
                <div style={styles.rightWrapper}>
                    {stage === "login" && <Transition>
                        <Login styles={styles} callbackUrl={callbackUrl} message={message} changeStage={handleChangeState} openAlert={openAlert} />
                    </Transition>}
                    {stage === "register" && <Transition>
                        <Register styles={styles} callbackUrl={callbackUrl} message={message} changeStage={handleChangeState} openAlert={openAlert} />
                    </Transition>}
                    {stage === "lost-password" && <Transition>
                        <LostPassword styles={styles} openAlert={openAlert} />
                    </Transition>}
                    {stage === "reset-password" && <Transition>
                        <ResetPassword styles={styles} email={searchParams.get("email")!} token={searchParams.get("token")!} openAlert={openAlert} />
                    </Transition>}  
                </div>          
            </div>
            <Footer />
            <Alert />
        </>
    );
}