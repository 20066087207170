import React from 'react';
import { Link } from 'react-router-dom';
import { SEOKeywords } from '../../helpers/LayoutTexts';

interface Props {
  styles: any;
}

export const FooterMenu: React.FC<Props> = (props) => {

  const styles = props.styles;

  const menuList: { label:any, items:any[] }[] = [
    { label: "Government Degrees",      
      items: [
        { item: <Link to={'/government-degrees' + SEOKeywords.governmentDegree} style={styles.item}>{"All Degrees"}</Link> },
        { item: <Link to={'/government-degrees' + SEOKeywords.governmentDegree} style={styles.item}>{"Degrees By Subjects"}</Link> }
      ] 
    },
    { label: "Private Degrees",      
      items: [
        { item: <Link to={'/private-degrees' + SEOKeywords.privateDegree} style={styles.item}>{"All Degrees"}</Link> },
        { item: <Link to={'/private-degrees' + SEOKeywords.privateDegree} style={styles.item}>{"Degrees By Category"}</Link> }
      ] 
    },
    { label: "Government Diplomas",      
      items: [
        { item: <Link to={'/government-diplomas' + SEOKeywords.governmentDiploma} style={styles.item}>{"All Diplomas"}</Link> },
        { item: <Link to={'/government-diplomas' + SEOKeywords.governmentDiploma} style={styles.item}>{"Diplomas By Category"}</Link> }
      ] 
    },
    { label: "Private Diplomas",      
      items: [
        { item: <Link to={'/private-diplomas' + SEOKeywords.privateDiploma} style={styles.item}>{"All Diplomas"}</Link> },
        { item: <Link to={'/private-diplomas' + SEOKeywords.privateDiploma} style={styles.item}>{"Diplomas By Category"}</Link> }
      ] 
    },
    { label: "Settings",      
      items: [
        { item: <Link to={'/contact'} style={styles.item}>{"Contact Us"}</Link> },
        { item: <Link to={'/termsAndConditions'} style={styles.item}>{"Terms And Conditions"}</Link> },
        { item: <Link to={'/privacyPolicy'} style={styles.item}>{"Privacy Policy"}</Link> }
      ] 
    }
  ];

  return (
    <div style={styles}>                                    
      {menuList.map((menuItem, index) =>            
        <div key={index} style={styles.menuItem}>            
          <div style={styles.label}>{menuItem.label}</div>
          <ul style={styles.list}>                                    
            {menuItem.items.map((item, index) => 
              <li key={index}>
                {item.item}
              </li>
            )}
          </ul>
        </div>
      )}                      
    </div>
  );
}