import { Color } from "../enums/Color";
import { pageLayout } from "./PageLayout";

export const searchPage = {
    mobile: {    
        ...pageLayout.mobile,
        subContainer: {
            marginTop: "128px"
        },      
        tabs: {
            container: {
                marginTop: "70px"
            },
            linkWrapper: {                
                margin: "70px auto 0 auto"
            }
        },
        inputWrapper: {                                        
            marginTop: "50px"
        },
        searchButton: {
            height: "40px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px 0"
        },
        orText: {
            textAlign: "center",            
            color: Color.Black,
            fontSize: "10px",            
            marginTop: "-15px",
            marginBottom: "10px" 
        },
        allButton: {
            display: "flex",
            background: "transparent",
            color: Color.Green,
            fontSize: "14px",
            fontWeight: "bold",
            border: "none",
            marginLeft: "auto",
            marginRight: "auto"    
        },
        imageInput: {            
            width: "-webkit-fill-available",            
            border: "none",
            borderRadius: "5px",            
            marginTop: "30px",
            objectFit:"contain"
        },
        banner: {
            marginTop: "20px"
        },
        videoLabel: {
            fontSize: "16px",  
            fontWeight: "bold",          
            margin: "32px 0 0 0",
            textAlign: "center"
        },
        video: {
            width: "100%",
            height: "223px",
            borderRadius :"12px",
            margin: "8px 0 10px 0"
        },
        newAdsButton: {
            position: "absolute",
            top: "60px",
            right: "5%",
            height: "34px",            
            background: Color.DeepBlack,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontSize: "12px",
            fontWeight: "bold",
            padding: "0 10px"            
        },
        switch: {
            wrapper: {
                display: "flex",
                marginTop: "16px"
            },
            label: {
                fontSize: "16px"                               
            }
        }
    },
    desktop: {
        ...pageLayout.desktop,
        subContainer: {
            marginTop: "60px"
        },            
        tabs: {
            container: {
                marginTop: "40px"
            },            
            linkWrapper: {            
                margin: "-25px auto 0 auto"
            }
        },
        inputWrapper: {                                        
            marginTop: "50px"
        },
        searchButton: {
            height: "60px",
            width: "-webkit-fill-available",            
            borderRadius: "10px",            
            fontSize: "20px",
            marginTop: "60px"
        },
        orText: {
            fontSize: "15px",  
            fontWeight: "bold",          
            marginTop: "0px",
            marginBottom: "15px" 
        },
        allButton: {
            fontSize: "20px"
        },
        videoLabel: {
            fontSize: "18px",               
            margin: "60px 0 0 0"            
        },
        video: {
            width: "100%",
            height: "386px",
            borderRadius :"20px",
            margin: "12px 0 20px 0"
        },
        newAdsButton: {
            display: "block",
            position: "relative",
            top: "unset",
            right: "unset",
            height: "36px",
            margin: "-44px 0 0 auto"
        },        
        switch: {
            wrapper: {                
                marginTop: "18px"
            },
            label: {
                fontSize: "18px"                             
            }
        }
    }
}