import React, { useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill/modules/history';
// import { ReactComponent as Undo } from '../../assets/svg/undo.svg';
// import { ReactComponent as Redo } from '../../assets/svg/redo.svg';


interface Props {
    value?: string;
    onChange(content: any): void;
    styles?: any;
    disabled?: boolean;
}

export const QuillEditor: React.FC<Props> = (props) => {
    const editorRef = useRef<any>(null);
    const quillRef = useRef<any>(null);
    const toolbarRef = useRef<any>(null);


    useEffect(() => {
        quillRef.current = new Quill(editorRef.current, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: toolbarRef.current,
                    handlers: {
                        'undo': undo,
                        'redo': redo
                    }
                },
                history: {
                    delay: 1000,
                    maxStack: 500,
                    userOnly: true
                },
                imageResize: false
            }
        });

        const removeImagesAndVideos = () => {
            const editor = quillRef.current;
            const editorContent = editor.getContents();
            let modified = false;

            editorContent.ops.forEach((op: any, index: number) => {
                if (op.insert && (op.insert.image || op.insert.video)) {
                    delete editorContent.ops[index].insert.image;
                    delete editorContent.ops[index].insert.video;
                    modified = true;
                }
            });
            if (modified) {
                editor.setContents(editorContent);
            }
        };

        quillRef.current.on('text-change', () => {
            removeImagesAndVideos();
            props.onChange(quillRef.current.root.innerHTML);
        });

        if (props.value) {
            quillRef.current.root.innerHTML = props.value;
        }

        if(props.disabled) {
            quillRef.current.enable(false);
        }
    }, []);

    const undo = () => {
        quillRef.current.history.undo();
    };

    const redo = () => {
        quillRef.current.history.redo();
    };


    return (
        <div style={props.styles}>
            <div ref={toolbarRef}>
                <div className='iconWrapper'>
                    <span className="ql-formats">
                        <select className="ql-font"></select>
                        <select className="ql-size"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <button className="ql-strike"></button>
                    </span>
                    <span className="ql-formats">
                        <select className="ql-color"></select>
                        <select className="ql-background"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-script" value="sub"></button>
                        <button className="ql-script" value="super"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-header" value="1"></button>
                        <button className="ql-header" value="2"></button>
                        <button className="ql-blockquote"></button>
                        <button className="ql-code-block"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered"></button>
                        <button className="ql-list" value="bullet"></button>
                        <button className="ql-indent" value="-1"></button>
                        <button className="ql-indent" value="+1"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-direction" value="rtl"></button>
                        <select className="ql-align"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-link"></button>
                    </span>
                    {/* <span className="ql-formats">
                        <button className="ql-undo"><Undo /></button>
                        <button className="ql-redo"><Redo /></button>
                    </span> */}
                </div>
            </div>
            <div ref={editorRef}></div>
        </div>
    );
}