const adClientId = "ca-pub-6427110161920769";
const currentYear = new Date().getFullYear();

export const CommonTexts = {
    apiBaseUrl: "https://api.tuteclass.com/api/", 
    // apiBaseUrl: "http://localhost:7542/api/"    
}

export const Keys = {
    pdftron: "demo:1720748980103:7f9fe7090300000000d3425bb18c480f9977fcb391b10ba3934c11ee81"
}

export const HomeTexts = {
    title: "TUTECLASS : Degrees, Diplomas, Teachers and Classes in Sri Lanka",
    description: "Find all Degrees, Diplomas, Teachers and Classes. Degrees and diplomas offer by all government and private universities. All tuition classes and teachers.",
    adClient: adClientId,
    adSlotOne: "2053183502",
    adSlotTwo: "6088932756"
}

export const HeaderTexts = {    
    heading: "The Wisdom Pathway",    
    desktopText: "tuteclass.com is an application that caters useful information and services for the learn force of Sri Lanka who are looking for good opportunities in higher studies including degrees and diplomas of different streams. It is a virtual platform which allows quick access to the accurate information that you need and guide you in achieving your higher education goals.",
    mobileText: "tuteclass.com is an application that provide valuable resources and services for teachers and students in Sri Lanka. The goal of the tuteclass.com is to bring distributed and not easily accessible information, resources and services to one digital platform and make easily accessible.",
    mobileSearch: "I'm looking for...",
    desktopSearch: "I'm looking for... (Eg: Degree Name, University)",
    defaultAvatar: process.env.PUBLIC_URL + "/images/default_avatar.jpg"    
}

export const FooterTexts = {
    address: "Bandaranayake Mawatha, Moratuwa, Colombo 11.",
    email: "info@clipess.com",
    telephone: "+94719464357",    
    copyright: `© ${currentYear} CLIPESS (PRIVATE) LIMITED. ALL RIGHTS RESERVED`
}

export const LoginTexts = {
    loginError: "The username or password is incorrect"
}

export const DegreeTexts = {
    title: "TUTECLASS : Degrees in Sri Lanka",
    description: "Find all degrees offered by government and private universities in Sri Lanka. Find degrees offered by government universities based on A/L streams and subjects.",    
    adClient: adClientId,
    adSlotOne: "8997342712",
    adSlotTwo: "8805771021"    
}

export const DiplomaTexts = {
    title: "TUTECLASS : Diplomas in Sri Lanka",
    description: "Find all diplomas offered by government and private universities in Sri Lanka. Find diplomas offered by government universities based on related category.",        
    adClient: adClientId,
    adSlotOne: "7183382379",
    adSlotTwo: "1579115171" 
}

export const TeacherTexts = {
    title: "TUTECLASS : Teachers in Sri Lanka",
    description: "Find tuition classes and teachers for any subject in Sri Lanka. Advanced level, ordinary level and primary tuition classes for all subjects. Find best teachers.",
    defaultTeacherAd: process.env.PUBLIC_URL + "/images/default_teacher_ad.jpg",
    defaultOnlineClass: "",
    adClient: adClientId,
    adSlotOne: "4013706827",
    adSlotTwo: "3959287387" 
}

export const FormTexts = {
    submitError : "Some of the required fields are missing."
}

export const Contacts = {
    address: {
        no: "465/2A,",
        road: "High Level Road,",
        city: "Maharagama."
    },
    email: "info@clipess.com",
    telephone: "+94719464357"
}

export const SEOKeywords = {
    governmentDegree: "/government-university-degrees-in-sri-lanka",
    privateDegree: "/private-university-degrees-in-sri-lanka",
    governmentDiploma: "/government-university-diplomas-in-sri-lanka",
    privateDiploma: "/private-university-diplomas-in-sri-lanka",
    teacher: "/teachers-and-tuition-classes-in-sri-lanka",
    onlineClass: "/online-classes-in-sri-lanka"
}

export const ErrorPageTexts = {
    connect: "We couldn’t connect the wires",
    unavailable: "The page you are looking for might have been removed or is temporarily unavailable.",
    copyright: `© ${currentYear} CLIPESS (PRIVATE) LIMITED. ALL RIGHTS RESERVED`    
}
