import { useEffect, useState } from "react";
import Slider from 'react-slick';
import { ImageSlideLoader } from "../../loaders/ImageSlideLoader";
import { useNavigate } from "react-router-dom";
import { SEOKeywords } from "../../helpers/LayoutTexts";

interface Props {
    styles: any;
    title: string;
    content: string;
}

const sliderLinks = {    
    sin: [
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_1",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_2",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_3",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_4",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_5",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_sin_6"
    ],
    tam: [
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_1",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_2",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_3",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_4",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_5",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_tam_6"
    ],
    eng: [
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_eng_1",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_eng_2",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_eng_3",
        "https://tuteclass.s3.ap-southeast-1.amazonaws.com/common/sliders/diploma_eng_4"
    ]    
}

export const DiplomaSlider: React.FC<Props> = (props) => {         
    const styles = props.styles;   
    const navigate = useNavigate(); 
    const [sliders, setSliders] = useState<string[]>([]);    

    useEffect(() => {   
        getSliders();              
    }, []);
    
    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        rows: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        useTransform: false,
        dots: true,
        appendDots: (dots: any) => (
            <div>
                <ul style={{ padding: "unset" }}>{dots}</ul>
            </div>
        )
    };
    
    const getSliders = () => {
        let languageCode = localStorage.getItem('languageCode');        
        switch (languageCode) {
            case "SIN": {
                setSliders(sliderLinks.sin);               
                break;
            }
            case "TAM": {
                setSliders(sliderLinks.tam);
                break;
            }
            case "ENG": {
                setSliders(sliderLinks.eng);
                break;
            }
        }
    }
    
    const toDiplomas = () => {
        navigate('/government-diplomas' + SEOKeywords.governmentDiploma);
    }

    return (
        <>
            {props.title ? <div style={{...styles, ...styles.toggleWrapper}}>
                <div style={{...styles.contentWrapper, ...styles.contentWrapper.toggle}}>
                    <div style={styles.title}>
                        {props.title}
                    </div>
                    <div style={styles.content}>
                        {props.content}
                    </div>
                </div>                 
                <div style={{...styles.imageWrapper, ...styles.imageWrapper.toggle}}>
                    <Slider {...sliderSettings}>
                        {sliders.map((slider, index) =>
                            <div key={index} onClick={toDiplomas}>
                                <img src={slider} style={styles.slider} alt="TuteClass diploma"/>
                            </div>
                        )}
                    </Slider>
                </div>                           
            </div>
            : <ImageSlideLoader styles={styles.slideLoader}/>}
        </>
    )
}