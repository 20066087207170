import { Color } from "../enums/Color";

export const homePage = {
    mobile: {            
        wrapper: {                           
            margin: "20px 3% 0 3%",            
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        item: {
            width: "50%",
            boxSizing: "border-box", 
            marginTop: "70px",
            textAlign: "center"
        },
        pageTag: {
            textDecoration: "none"
        },
        icon: {
            height: "70px",
            width: "90px",
            marginBottom: "10px"
        },
        label: {
            color: Color.Green,            
            fontWeight: "bold",
            textAlign: "center"
        },
        slider: {                      
            display: "flex",
            flexDirection: "column" as "column",            
            margin: "50px 4% 0 4%",  
            overflow: "hidden",      
            contentWrapper: { },
            imageWrapper: {
                marginBottom: "1.21vw"
            },
            title: {                
                fontSize: "22px",
                fontWeight: "bold",
                color: Color.SharpAsh
            },
            content: {
                marginTop: "20px",
                fontSize: "16px",   
                lineHeight: "1.4",
                color: Color.SharpAsh
            },
            slider: {
                width: "98%",                
                border: "2px solid black",
                borderRadius: "20px",
                margin: "10px 1%"
            },
            slideLoader: {
                display: "flex",
                flexDirection: "column",            
                margin: "50px 4% 0 4%",  
                overflow: "hidden",      
                contentWrapper: { },
                imageWrapper: { }
            }
        },
        banner: {
            margin: "50px 4% 0 4%"
        }
    },
    desktop: {           
        wrapper: { 
            margin: "20px 3% 0 3%"            
        },
        item: {
            width: "33%",            
            marginTop: "70px"            
        },      
        label: {            
            fontSize: "20px"
        },
        slider: {                      
            flexDirection: "row" as "row",
            margin: "100px 8%",
            toggleWrapper: {
                flexDirection: "row-reverse"
            },
            contentWrapper: {
                width: "35%",
                marginRight: "5%",
                toggle: {
                    marginRight: "unset",
                    marginLeft: "5%"
                }
            },
            imageWrapper: {
                width: "55%",
                marginLeft: "5%",
                marginBottom: "0.26vw",
                toggle: {
                    marginLeft: "unset",
                    marginRight: "5%"
                }
            },
            title: {
                marginTop: "10px",
                fontSize: "36px"
            },
            content: {
                marginTop: "30px",
                fontSize: "20px"
            },
            slider: {
                width: "96%",
                height: "640px",                
                borderRadius: "20px",
                margin: "10px 2%",                
                cursor: "pointer",            
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)"                
            },
            slideLoader: {
                margin: "100px 8%",
                flexDirection: "row-reverse",
                contentWrapper: {
                    width: "35%",
                    marginLeft: "5%"
                },
                imageWrapper: {
                    width: "55%",
                    marginRight: "5%"                
                }
            }
        },        
        banner: {
            margin: "0 8%"
        }
    }
}