import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { ToolBar } from "../../components/Shared/ToolBar";
import { PrivateDiplomaView } from "../../models/PrivateDiplomaView";
import { Localization } from "../../models/Localization";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getDiploma } from "../../services/PrivateDiplomaService";
import { DetailLoader } from "../../loaders/DetailLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { Helmet } from "react-helmet-async";

export const PrivateDiplomaDisplayPage: React.FC = () => {
    let params = useParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("displayPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [diplomaView, setDiplomaView] = useState<PrivateDiplomaView>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {
        getLocalizations();  
        getDiplomaView();                            
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("displayPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(8, 26) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getDiplomaView() {        
        const diplomaView = await getDiploma(params.diplomaId!) as unknown as PrivateDiplomaView;
        setDiplomaView(diplomaView);         
        setLoading(false);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer />
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            {loading ? <DetailLoader /> : 
            <>
                <Helmet>
                    <title>{diplomaView?.SEOTitle}</title>
                    <meta name="description" content={diplomaView?.SEODescription}/>                    
                </Helmet>
                <div style={styles.container}>
                    <div>
                        <div style={styles.title}>{diplomaView?.PrivateDiplomaName}</div>
                        <div style={styles.titleSecondary}>{diplomaView?.PrivateDiplomaName_ENG}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwInstituteLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{diplomaView?.PrivateInstituteName}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDiplomaPeriodLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{diplomaView?.PrivateDiplomaPeriodName}</div>
                    </div>
                    {diplomaView?.RequiredQualifications && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwRequiredQualificationLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: diplomaView?.RequiredQualifications}}></div>
                    </div>}
                    {diplomaView?.PrivateDiplomaDetail && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDiplomaDetailLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: diplomaView?.PrivateDiplomaDetail}}></div>
                    </div>}
                    {diplomaView?.PrivateDiplomaContent && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDiplomaContentLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: diplomaView?.PrivateDiplomaContent}}></div>
                    </div>}
                    {diplomaView?.JobOpportunities && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwJobOpportunitiesLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: diplomaView?.JobOpportunities}}></div>
                    </div>}
                </div>
            </>}
        </div>
    )
}