import React from "react";
import { Link } from "react-router-dom";
import { SEOKeywords } from "../../helpers/LayoutTexts";

interface Props {
    styles: any;
}

export const SiderMenu: React.FC<Props> = (props) => {
    const styles = props.styles;  

    const menuList: any[] = [
        <Link to={'/'} style={styles.label}>{"Home"}</Link>, 
        <Link to={'/government-degrees' + SEOKeywords.governmentDegree} style={styles.label}>{"Degrees"}</Link>,        
        <Link to={'/government-diplomas' + SEOKeywords.governmentDiploma} style={styles.label}>{"Diplomas"}</Link>,        
        <Link to={'/teachers' + SEOKeywords.teacher} style={styles.label}>{"Tuition Teachers"}</Link>,
        <Link to={'/online-classes' + SEOKeywords.onlineClass} style={styles.label}>{"Online Classes"}</Link>,
        <Link to={'/chat'} style={styles.label}>{"Ask Anything"}</Link>,
        <Link to={'/settings'} style={styles.label}>{"Settings"}</Link>
    ];

    return (
        <div style={styles}>                                    
            {menuList.map((menuItem, index) =>            
                <div key={index} style={styles.itemWrapper}>{menuItem}</div>
            )}                      
        </div>
    );
}