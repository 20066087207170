import { useNavigate } from "react-router-dom";
import { UserType } from "../enums/UserType";

const useErrorPage = () => {
    const navigate = useNavigate();

    const toErrorPage = (error: any, userType?: UserType) => {
        if(error.response.status === 401) {
            if(userType === UserType.Teacher) {
                localStorage.removeItem("teacher");
                localStorage.removeItem("token");
                navigate('/teacher/login');
            }
            else {
                localStorage.removeItem("student");
                localStorage.removeItem("token");
                navigate('/student/login');
            }            
        }
        else {
            navigate('/error', { state: { error: error.message }});
        }
    }
    
    return [toErrorPage];
};

export default useErrorPage;