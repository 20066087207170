import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { AuthContext } from '../../providers/AuthContextProvider';
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { merge } from 'react-merge';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { postStudentLogout } from '../../services/StudentService';
import { LoggedTeacher } from '../../models/LoggedTeacher';
import { LoggedStudent } from '../../models/LoggedStudent';
import { postTeacherLogout } from '../../services/TeacherService';

interface Props {
    styles?: any;
    disableStudentLogin?: boolean;
    disableTeacherLogin?: boolean;
}

export const ProfileEntrance: React.FC<Props> = (props) => {  
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();
    const student: LoggedStudent | null = authContext.getLoggedStudent();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profileEntrance"));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [userAvatar, setUserAvatar] = useState<string>(Texts.defaultAvatar);

    useEffect(() => {   
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("profileEntrance"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                    
    }, [isMobile]);

    useEffect(() => {
        // if(teacher && teacher.Image) {
        //     setUserAvatar(teacher.Image);
        // }
        // else if(student && student.Image) {
        //     setUserAvatar(student.Image);
        // }
    }, []);    

    const profileItems: any[] = [        
        { item: 
            <div onClick={() => handleToProfile()} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Profile"}</DropdownItem>
            </div>
        },
        { item: 
            <div onClick={() => handleLogout()} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Logout"}</DropdownItem>
            </div>
        }   
    ]

    const handleToProfile = () => {
        if(teacher) {
            navigate('/teacher-ads/my-profile');
        }
        else if(student) {
            navigate('/studentProfile');
        }
    }    

    const handleStudentLogin = () => {               
        navigate('/student/login');
    }

    const handleTeacherLogin = () => {               
        navigate('/teacher/login');
    }

    const handleLogout = () => {
        localStorage.removeItem("token");
        if(teacher) {
            postTeacherLogout(teacher.TeacherId, teacher.Token);
            localStorage.removeItem("teacher");
            navigate('/teacher/login');
        }
        else if(student) {
            postStudentLogout(student.StudentId, student.Token);
            localStorage.removeItem("student");
            navigate('/student/login');
        }
    }

    return (
        <div style={styles.wrapper}>                        
            {(teacher || student) ? <div style={styles.userWrapper}>
                <Dropdown styles={styles.userDropdown} items={profileItems}>
                    <img src={userAvatar} style={styles.userAvatar} />
                </Dropdown>
            </div> : 
            <div style={styles.buttonWrapper}>
                {!props.disableStudentLogin && <Button type="button" style={styles.loginButton} onClick={handleStudentLogin}>{"Student Login"}</Button>}
                {!props.disableTeacherLogin && <Button type="button" style={{...styles.loginButton, ...styles.teacherLogin}} onClick={handleTeacherLogin}>{"Teacher Login"}</Button>}
            </div>}
        </div>
    );
}