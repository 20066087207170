import { useEffect, useState } from "react";

interface Props {
    children: React.ReactNode;
}

export const Transition: React.FC<Props> = (props) => {
    const [isMounted, setIsMounted] = useState(false);
    
    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <div className={isMounted ? "transition-enter-active" : "transition-enter"}>
            {props.children}
        </div>
    );
};