import { useMediaQuery } from "react-responsive";
import { Color } from "../enums/Color";
import { merge } from "react-merge";
import ContentLoader from "react-content-loader";

const loaderStyle = {
    mobile : {
        width: "90.34vw",
        backgroundColor: Color.White, 
        padding: "0 4.83vw",
        margin: "24.15vw 0 37.20vw",
        height: "71.50vw"
    },
    desktop: {
        borderRadius: "0.52vw",
        border: `2px solid ${Color.LightAsh}`,
        padding: "2.6vw",
        width: "29.78vw",
        height: "19.90vw",
        margin: "unset"
    }
};
  
export const ResetPasswordLoader : React.FC = () => {
    const isMobile = useMediaQuery({query : "(max-width: 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }
    
    return (
        <div style={styles}>
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width="auto"
                height={styles.height}
            > 
                {isMobile ? <>
                    <rect x="4.27%" y="0" rx="3" ry="3" width="55.47%" height="11.86%" />
                    <rect x="4.27%" y="22.46%" rx="3" ry="3" width="77.6%" height="7.77%" />
                    <rect x="0" y="34.12%" rx="3" ry="3" width="100%" height="16.89%" />
                    <rect x="0" y="54.39%" rx="3" ry="3" width="100%" height="16.89%" />
                    <rect x="0" y="83.11%" rx="3" ry="3" width="100%" height="16.89%" />
                </> : 
                <>
                    <rect x="1.75%" y="0" rx="3" ry="3" width="43.71%" height="9.46%" />
                    <rect x="1.75%" y="17.91%" rx="3" ry="3" width="45.80%" height="5.70%" />
                    <rect x="0" y="27.35%" rx="3" ry="3" width="100%" height="19.94%" />
                    <rect x="0" y="51.57%" rx="3" ry="3" width="100%" height="19.94%" />
                    <rect x="0" y="80.06%" rx="3" ry="3" width="100%" height="19.94%" />
                </>} 
            </ContentLoader>  
        </div>
    )
}