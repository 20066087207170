import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../../components/Shared/ToolBar";
import { StyleContext } from "../../providers/StyleContextProvider";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { AuthContext } from "../../providers/AuthContextProvider";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { SearchContentLoader } from "../../loaders/SearchContentLoader";
import { Input } from "../../components/Shared/Inputs/Input";
import { Button } from "../../components/Shared/Button";
import { Localization } from "../../models/Localization";
import { getPageLocalizations } from "../../services/CommonService";
import { useAlert } from "../../hooks/useAlert";
import { QuillEditor } from "../../components/Shared/QuillEditor";
import { OnlineClassWeek, OnlineClassWeekValidation } from "../../models/OnlineClassWeek";
import Dropzone from "react-dropzone";
import { ReactComponent as FileUpload } from "../../assets/svgs/file-upload.svg";
import { ReactComponent as CloseCircle } from "../../assets/svgs/close-circle-ash.svg";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { getOnlineClassWeek, saveClassWeek } from "../../services/ClassService";
import { Switch } from "../../components/Shared/Inputs/Switch";
import { LoggedTeacher } from "../../models/LoggedTeacher";
import { getClassTuteURL, validateTute } from "../../helpers/Common";
import PDFViewer from "../../components/Shared/PDFViewer";

const intialFormInputs: OnlineClassWeek = {
    OnlineClassWeekId: 0,
    OnlineClassId: 0,
    PaymentRequired: true
}

const initialValidation: OnlineClassWeekValidation = {
    Title: {},
    Date: {},
    Time: {},  
    DurationInHours: {},
    DurationInMins: {}    
}

export const WeekManagePage: React.FC = () => {
    let params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const classId: number = +params.classId!;
    const weekId: number = +params.weekId!;        
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("classManagePage"));
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();
    const [tuteFile, setTuteFile] = useState<File>();
    const [tuteFileUrl, setTuteFileUrl] = useState<string>();    
    const [classWeek, setClassWeek] = useState<OnlineClassWeek>(intialFormInputs);
    const [validation, setValidation] = useState<OnlineClassWeekValidation>(initialValidation);
    const [loading, setLoading] = useState<boolean>(false);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const {Alert, openAlert} = useAlert();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const bannerType = isMobile ? 7 : 8;     
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];        
    const [isExpired, setIsExpired] = useState<boolean>(false);
    
    useEffect(() => {    
        if(teacher !== null) {              
            getLocalizations(); 
            if(weekId === 0) {
                const newFormInputs: OnlineClassWeek = {
                    OnlineClassWeekId: 0,
                    OnlineClassId: classId,
                    PaymentRequired: true
                }
                setClassWeek(newFormInputs);
            }      
            else {
                getOnlineClassWeekData();
            }     
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login to create a new class week." };
            navigate({
                pathname: '/teacher/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("classManagePage"));          
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 37)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getOnlineClassWeekData() {  
        setLoading(true);              
        await getOnlineClassWeek(weekId)
        .then((weekData: OnlineClassWeek) => {            
            const editFormInputs: OnlineClassWeek = {
                OnlineClassWeekId: weekData.OnlineClassWeekId,
                OnlineClassId: weekData.OnlineClassId,
                Title: weekData.Title,
                Date: weekData.Date!.split('T')[0],
                Time: weekData.Time,
                DurationInHours: weekData.DurationInHours,
                DurationInMins: weekData.DurationInMins,
                Description: weekData.Description,
                PaymentRequired: weekData.PaymentRequired,
                ZoomLink: weekData.ZoomLink,
                ZoomPassword: weekData.ZoomPassword,
                ZoomRecordingLink: weekData.ZoomRecordingLink,
                HasTute: weekData.HasTute,
                TuteName: weekData.TuteName
            };
            setClassWeek(editFormInputs);
            if(weekData.HasTute) {
                const tuteURL: string = getClassTuteURL(weekId, teacher!.TeacherId, teacher!.Token);
                setTuteFileUrl(tuteURL);                
            }
            if(weekData.IsExpired || weekData.IsCompleted) {
                setIsExpired(true);
            }
            setLoading(false);                  
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value; 
        setClassWeek((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }       
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const handleFileChange = (uploadedFiles: File[]) => { 
        let fileUrl: string;
        if(uploadedFiles.length > 0) {
            const uploadedTute = uploadedFiles[0];
            const tuteValidation = validateTute(uploadedTute);
            if(tuteValidation.valid) {
                setTuteFile(uploadedTute);
                fileUrl = URL.createObjectURL(uploadedTute);
                setTuteFileUrl(fileUrl);                                                                                                   
            }
            else{
                openAlert("error", tuteValidation.invalidMessage!);
            }                                           
        }
        // Revoke the object URL on component unmount
        return () => URL.revokeObjectURL(fileUrl);
    }
    
    const removeSelectedFile = () => {
        if(!isExpired) {
            setTuteFile(undefined);
            setTuteFileUrl(undefined);
            setClassWeek((values: any) => ({ ...values, ["HasTute"]: false }));
        }
    }

    const handlePaymentStatusChange = () => {
        if(!isExpired) {
            setClassWeek((values: any) => ({ ...values, ["PaymentRequired"]: !classWeek.PaymentRequired }));
        }
    }

    const handleDescriptionChange = (description: string) => {        
        setClassWeek((values: any) => ({ ...values, ["Description"]: description }));              
    }

    const validateInput = (name: string, value: string | number) => {                
        if(name === "Title" || name === "Date" || name === "Time") {  
            if(value === "") {          
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));   
            }  
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }       
        }        
        if(name === "DurationInHours") {            
            if(+value < 0 || +value > 24) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 24"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }    
        if(name === "DurationInMins") {            
            if(+value < 0 || +value > 60) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 60"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }       
    }

    const validateForm = (): number => {
        let formValidation: OnlineClassWeekValidation = {
            Title: {valid: true},
            Date: {valid: true},
            Time: {valid: true},  
            DurationInHours: {valid: true},
            DurationInMins: {valid: true}
        }        
        if(classWeek.Title === undefined || classWeek.Title === "") {
            formValidation.Title = {valid: false, invalid: true, invalidMessage: "Title is required"};            
        }        
        if(classWeek.Date === undefined || classWeek.Date === "") {
            formValidation.Date = {valid: false, invalid: true, invalidMessage: "Date is required"};            
        } 
        if(classWeek.Time === undefined || classWeek.Time === "") {
            formValidation.Time = {valid: false, invalid: true, invalidMessage: "Time is required"};            
        }
        if(classWeek.DurationInHours) {                                
            if(classWeek.DurationInHours < 0 || classWeek.DurationInHours > 24) {
                formValidation.DurationInHours = {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 24"};
            } 
        }
        if(classWeek.DurationInMins) {                                
            if(classWeek.DurationInMins < 0 || classWeek.DurationInMins > 60) {
                formValidation.DurationInHours = {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 60"};
            }
        }
        setValidation(formValidation);        
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key]!.valid).length;
        return validInputCount;
    }

    async function submitResponse(event: React.FormEvent<HTMLFormElement>) {         
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key]!.valid).length;
        if(validInputCount < 5) {
            validInputCount = validateForm();
        }
        if(validInputCount === 5) {  
            setLoading(true);          
            const classWeekString = JSON.stringify(classWeek);
            const formData = new FormData();
            formData.append('response', encodeURIComponent(classWeekString));  
            formData.append('tuteFile', tuteFile!);
            await saveClassWeek(formData)
            .then((success: boolean) => {  
                setLoading(false);
                if(success) {
                    openAlert("success", "Class week has been submitted successfully.");  
                    navigate(-1);                                                       
                }
                else {
                    openAlert("error", "Oops! something went wrong.");
                }
            })
            .catch((error) => {            
                //toErrorPage(error);
            });  
        } 
        else {
            openAlert("error", "Invalid form inputs.");
        }            
    }  

    return (
        <>            
            <ToolBar>{localizations.find(({ ElementName }) => ElementName === 'vwTitle')?.ElementValue!}</ToolBar>
            <DesktopMenuDrawer/>             
            <div style={styles.container}>
                {loading ? <SearchContentLoader/> : <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwTitle')?.ElementValue!}
                    </h1>
                    <form style={styles.week.formWrapper} onSubmit={submitResponse}>  
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwTitleLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"text"}
                                name={"Title"}
                                value={classWeek.Title}
                                styles={styles.week.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}  
                                disabled={isExpired}                                             
                                validation={validation.Title}    
                            />
                        </div>              
                        <div style={{...styles.week.inputWrapper, ...styles.week.rowWrapper}}>
                            <div style={styles.week.halfWrapper}>
                                <label style={styles.week.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwDateLabel')?.ElementValue!}
                                </label>
                                <Input 
                                    type={"date"}
                                    name={"Date"}
                                    value={classWeek.Date}
                                    min={formattedToday}
                                    styles={styles.week.input}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur} 
                                    disabled={isExpired}  
                                    validation={validation.Date}                       
                                />
                            </div> 
                            <div style={styles.week.halfWrapper}>
                                <label style={styles.week.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwTimeLabel')?.ElementValue!}
                                </label>                        
                                <Input
                                    type={"time"}
                                    name={"Time"}
                                    value={classWeek.Time}
                                    styles={styles.week.input}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                    disabled={isExpired}                                                           
                                    validation={validation.Time} 
                                />                        
                            </div>                   
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwDurationLabel')?.ElementValue!}
                            </label>
                            <div style={styles.week.rowWrapper}>
                                <div style={styles.week.halfWrapper}>
                                    <Input 
                                        type={"number"}
                                        name={"DurationInHours"}
                                        value={classWeek.DurationInHours}
                                        min={0}
                                        max={24}
                                        placeholder={localizations.find(({ ElementName }) => ElementName === 'vwHourLabel')?.ElementValue!}
                                        styles={styles.week.input}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur} 
                                        disabled={isExpired}  
                                        validation={validation.DurationInHours}                       
                                    />                            
                                </div>
                                <div style={styles.week.halfWrapper}>
                                    <Input
                                        type={"number"}
                                        name={"DurationInMins"}
                                        value={classWeek.DurationInMins}
                                        min={0}
                                        max={60}
                                        placeholder={localizations.find(({ ElementName }) => ElementName === 'vwMinLabel')?.ElementValue!}
                                        styles={styles.week.input}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                        disabled={isExpired}                                                           
                                        validation={validation.DurationInMins} 
                                    />
                                </div>
                            </div>                   
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwZoomLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"url"}
                                name={"ZoomLink"}
                                value={classWeek.ZoomLink}
                                styles={styles.week.input}
                                onChange={handleInputChange} 
                                disabled={isExpired}
                            />
                        </div> 
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwPasswordLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"text"}
                                name={"ZoomPassword"}
                                value={classWeek.ZoomPassword}
                                styles={styles.week.input}
                                onChange={handleInputChange} 
                                disabled={isExpired}
                            />
                        </div>
                        {isExpired && <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwRecordingLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"text"}
                                name={"ZoomRecordingLink"}
                                value={classWeek.ZoomRecordingLink}
                                styles={styles.week.input}
                                onChange={handleInputChange} 
                            />
                        </div>}
                        <div style={styles.week.switch.wrapper} onClick={handlePaymentStatusChange}>
                            <span style={styles.week.switch.label}>{localizations.find(({ ElementName }) => ElementName === 'vwPaymentLabel')?.ElementValue!}</span>
                            <Switch checked={classWeek.PaymentRequired!}/>                
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwTuteLabel')?.ElementValue!}
                                <span style={styles.week.labelWarning}>{"(Supported Files : pdf)"}</span>
                            </label>
                            <div style={styles.week.rowWrapper}>
                                {tuteFileUrl && <>
                                    <PDFViewer pdfUrl={tuteFileUrl} styles={styles.week.pdfViewer} />
                                    <CloseCircle style={styles.week.fileClose} onClick={removeSelectedFile} />
                                </>}
                                <div style={{...styles.week.fullWrapper, ...(tuteFileUrl && styles.week.halfWrapper)}}>
                                    <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)} disabled={isExpired} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ style: styles.week.uploadFile })} className="center-content">
                                                <input {...getInputProps()} /> 
                                                <div style={styles.week.uploadIconWrapper}>
                                                    <FileUpload style={styles.week.uploadIcon} />
                                                </div>
                                                <div style={styles.week.uploadText}>
                                                    {"Add a tute file if available."}                                  
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwDescriptionLabel')?.ElementValue!}
                            </label>
                            <QuillEditor 
                                value={classWeek.Description}
                                onChange={handleDescriptionChange}
                                styles={styles.week.editor}
                                disabled={isExpired}
                            /> 
                        </div>           
                        <Button type="submit" style={styles.week.submitButton}>                    
                            {localizations.find(({ ElementName }) => ElementName === 'vwSubmitBtn')?.ElementValue!}                    
                        </Button>
                    </form>
                </div>}
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
            <Alert/>
        </>
    )
}