import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { getDiplomas, getRequestModel } from "../../services/PrivateDiplomaService";
import { ListLoader } from "../../loaders/ListLoader";
import { NoData } from "../../components/Shared/NoData";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { Option } from "../../models/Option";
import { SEOKeywords } from "../../helpers/LayoutTexts";
import { getSeoName } from "../../helpers/Common";
import { Diploma } from "../../models/Diploma";
import { DiplomaResponseModel } from "../../models/DiplomaResponseModel";
import { SingleSelectInput } from "../../components/Shared/Inputs/SingleSelectInput";
import { DiplomaCard } from "../../components/Shared/DiplomaCard";
import { ReactComponent as DropdownIcon } from "../../assets/svgs/drop_down.svg";

export const PrivateDiplomaListPage: React.FC = () => { 
    const navigate = useNavigate();       
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("degreeListPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [isFilterLoad, setIsFilterLoad] = useState<boolean>(false);
    const [isFilterExpand, setIsFilterExpand] = useState<boolean>(false);
    const [categories, setCategories] = useState<Option[]>([]);     
    const [institutes, setInstitutes] = useState<Option[]>([]); 
    const [diplomaPeriods, setDiplomaPeriods] = useState<Option[]>([]); 
    const categoryId: number = searchParams.get("categoryId") === null ? -1 : +searchParams.get("categoryId")!;    
    const instituteId: number = searchParams.get("instituteId") === null ? -1 : +searchParams.get("instituteId")!;
    const diplomaPeriodId: number = searchParams.get("diplomaPeriodId") === null ? -1 : +searchParams.get("diplomaPeriodId")!;
    const keyword: string = searchParams.get("keyword") === null ? "" : searchParams.get("keyword")!;
    const [diplomas, setDiplomas] = useState<Diploma[]>([]);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [searchKeyword, setSearchKeyword] = useState<string>(""); 
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("degreeListPage"));        
    }, [isMobile]);
    
    useEffect(() => {
        getLocalizations();  
        getRequestModelData();
        setSearchKeyword(keyword);    
        expandFilterPanel();                            
    }, []);

    useEffect(() => { 
        getDiplomaList();                            
    }, [categoryId, instituteId, diplomaPeriodId, keyword]);

    async function getLocalizations() {                     
        await getPageLocalizations(8, 25)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList); 
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                    
    }

    async function getRequestModelData() {        
        await getRequestModel()
        .then((requestModel: any) => {
            setCategories(requestModel.Categories);
            setInstitutes(requestModel.PrivateInstitutes);
            setDiplomaPeriods(requestModel.DiplomaPeriods);    
            setIsFilterLoad(true);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });         
    }
    
    async function getDiplomaList() {
        setLoading(true);
        let responseModel: DiplomaResponseModel = {
            categoryId: categoryId,
            instituteId: instituteId,
            diplomaPeriodId: diplomaPeriodId,
            keyword: keyword
        }
        await getDiplomas(responseModel)
        .then((response: any) => {         
            setDiplomas(response);             
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });         
    }    
    
    const expandFilterPanel = () => {
        if(categoryId !== -1 || instituteId !== -1 || diplomaPeriodId !== -1 || keyword !== "") {
            setIsFilterExpand(true);
        }
    }

    const setSelectedCategory = (selectedCategoryId: number) => {        
        searchParams.set("categoryId", selectedCategoryId.toString());
        handleNavigate();
    }

    const setSelectedInstitute = (selectedInstituteId: number) => {        
        searchParams.set("instituteId", selectedInstituteId.toString());
        handleNavigate();
    }

    const setSelectedDiplomaPeriod = (selectedDiplomaPeriodId: number) => {        
        searchParams.set("diplomaPeriodId", selectedDiplomaPeriodId.toString());
        handleNavigate();
    }

    const setKeyword = (keywordInput: string) => {
        if(keywordInput === "") {
            searchParams.delete("keyword");
        }   
        else {                
            searchParams.set("keyword", keywordInput);
        }
        handleNavigate();
    }
    
    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchKeyword(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setKeyword(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleNavigate = () => {                                    
        const newUrl = "/private-diplomas/" + getPageSEO() + `?${searchParams}`;
        navigate(newUrl);                
    }

    const navigateToGovernmentDiplomas = () => {
        navigate("/government-diplomas" + SEOKeywords.governmentDiploma);
    }

    const getPageSEO = (): string => {        
        let categorySEO = "", instituteSEO = "private university";                        
        if(searchParams.get("categoryId") !== null && searchParams.get("categoryId") !== '-1') {                 
            let selectedCategoryId = +searchParams.get("categoryId")!;                
            categorySEO = "for " + categories.find(x => x.Value === selectedCategoryId)!.SecondaryOption!;
        }
        if(searchParams.get("instituteId") !== null && searchParams.get("instituteId") !== '-1') {                 
            let selectedInstituteId = +searchParams.get("instituteId")!;                
            instituteSEO = institutes.find(x => x.Value === selectedInstituteId)!.SecondaryOption!;
        }
        let pageSEO = `${instituteSEO} diplomas ${categorySEO} in sri lanka`;
        pageSEO = pageSEO.trim();
        return getSeoName(pageSEO);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer />
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            <div style={styles.accordionButtonsContainer}>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.left }}
                    onClick={navigateToGovernmentDiplomas} 
                >
                    {localizations.find(({ ElementName }) => ElementName === 'vwGovernmentLabel')?.ElementValue!}
                </div>
                <div style={{ ...styles.accordionButton, ...styles.accordionButton.right, ...styles.accordionButton.selected }}>
                    {localizations.find(({ ElementName }) => ElementName === 'vwPrivateLabel')?.ElementValue!}
                </div>
            </div>
            <div style={{...styles.filter, ...(isFilterExpand && styles.filter.expand)}}>
                <div style={{...styles.filterExpandButton, ...(isFilterExpand && styles.filterExpandButton.expand)}} onClick={() => setIsFilterExpand((prev) => !prev)}>
                    {"Search Filters"}
                    <DropdownIcon style={{...styles.expandButtonIcon, ...(isFilterExpand && styles.expandButtonIcon.up)}}/>
                </div>
                {(isFilterLoad && isFilterExpand) && <div style={styles.filterContent}>
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwCategoryLabel')?.ElementValue!}
                        options={categories}                    
                        selectedId={categoryId}
                        setSelected={setSelectedCategory}
                        searchEnabled={true}
                    />                    
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwInstituteLabel')?.ElementValue!}                        
                        options={institutes} 
                        selectedId={instituteId}
                        setSelected={setSelectedInstitute}
                        searchEnabled={true}
                    />
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwDiplomaPeriodLabel')?.ElementValue!}                        
                        options={diplomaPeriods} 
                        selectedId={diplomaPeriodId}
                        setSelected={setSelectedDiplomaPeriod}
                    /> 
                    <input 
                        type="text"
                        placeholder="Search by keywords"
                        value={searchKeyword}
                        onChange={(e) => handleKeywordChange(e)}                        
                        style={styles.searchInput}
                    />
                </div>}
            </div>
            {loading ? <ListLoader /> : 
            <div style={styles.container}>
                {diplomas.length === 0 ? <NoData /> : 
                <>
                    <div style={styles.listCount}>{diplomas.length + " Diplomas"}</div>
                    {diplomas.map((diploma, index) =>
                        <div key={index}>
                            <Link to={`/private-diploma/view/${diploma.DiplomaId}/` + getSeoName(diploma.DiplomaName_ENG + "-" + diploma.InstituteName_ENG)} style={styles.link}>
                                <DiplomaCard diploma={diploma} />
                            </Link>                            
                        </div>                     
                    )}
                </>}      
            </div>}
        </div>
    )
}