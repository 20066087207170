import { Color } from "../enums/Color";

export const studentClassPage = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",             
            padding: "0 5%"            
        },     
        listContainer: {
            margin: "70px 0 50px 0"
        }, 
        card: {
            borderRadius: "1.21vw",            
            padding: "2.41vw 3.62vw",
            marginBottom: "2.41vw",
            active: {
                background: Color.Nyanza,
                border: "1px solid " + Color.MediumGreen
            },
            expired: {
                background: Color.LightAsh,
                border: "none"
            },
            weekNo: {
                fontSize: "2.41vw",
                fontWeight: "bold",
                color: Color.DarkLiver
            },
            title: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Black
            },
            dateWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "1.54vw",
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray
            },
            rowWrapper: {
                display: "flex",
                alignItems: "center",
                width: "45%"
            },
            calendarIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw"
            },
            clockIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw",
                marginLeft: "10.14vw"
            },
            fee: {
                display: "flex",
                alignItems: "center",
                marginTop: "1.54vw",
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray
            },
            cashIcon: {
                height: "4.94vw",
                width: "auto",
                marginRight: "1.87vw"
            },
            tute: {
                display: "flex",
                alignItems: "center",
                marginTop: "1.54vw",
                fontSize: "3.38vw"
            },
            fileIcon: {
                height: "4.94vw",
                width: "auto",
                marginRight: "1.87vw"
            },
            downloadIcon: {
                height: "4.94vw",
                width: "auto",
                marginLeft: "1.87vw"
            },
            actionButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "8.45vw",
                width: "-webkit-fill-available",
                borderRadius: "1.69vw",
                color: Color.White,
                background: Color.Blue,
                fontSize: "3.38vw",
                fontWeight: "bold",
                margin: "4.35vw auto 2.41vw auto",
                attend: {
                    background: Color.MediumGreen
                },
                pay: {
                    background: Color.Blue
                }
            }
        },
        paymentDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            container: {
                background: Color.White,
                position: "fixed",
                top: "35%",
                right: "5%",
                width: "80%",
                height: "auto",
                padding: "10px 5%",                        
                borderRadius: "10px",
                zIndex: "2",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)"
            }
        },
        paymentMessage: {
            fontSize: "18px"
        },
        payButtonWrapper: {
            display: "flex",
            flexDirection: "column",
            margin: "24px 0 10px 0"
        },
        onlinePayButton: {
            height: "40px",
            background: Color.MediumGreen,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        },
        bankPayButton: {
            height: "40px",
            background: Color.Black,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        },
        streaming: {
            unauthorized: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                fontSize: "6.03vw",
                fontWeight: "bold",
                color: Color.Red,
                textAlign: "center"
            }
        }    
    },
    desktop: {        
        desktopTitle: {
            display: "block",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            zIndex: 1
        },
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        },     
        listContainer: {
            margin: "50px 0"
        },   
        card: {
            borderRadius: "0.52vw",  
            padding: "1.04vw 1.35vw",
            marginBottom: "0.52vw",
            cursor: "pointer",
            weekNo: {
                fontSize: "0.73vw"
            },
            title: {
                fontSize: "1.04vw"
            },
            dateWrapper: {
                marginTop: "0.625vw",
                fontSize: "0.83vw"
            },
            calendarIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw"
            },
            clockIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw",
                marginLeft: "2.86vw"
            },
            fee: {
                marginTop: "0.625vw",
                fontSize: "0.83vw"
            },
            cashIcon: {
                height: "1.24vw",
                marginRight: "0.81vw"
            },
            tute: {
                marginTop: "0.625vw",
                fontSize: "0.83vw"
            },
            fileIcon: {
                height: "1.24vw",
                marginRight: "0.81vw"
            },
            downloadIcon: {
                height: "1.24vw",
                marginLeft: "0.81vw"
            },
            actionButton: {
                height: "3.64vw",
                width: "20.78vw",
                borderRadius: "0.52vw",
                fontSize: "1.04vw",
                margin: "1.35vw auto 0 auto"
            }
        },
        paymentDialog: {
            container:{
                top: "28%",
                right: "unset",
                width: "32.6%",
                padding: "2% 1.7%",
                borderRadius: "10px",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
            }
        },
        paymentMessage: {
            fontSize: "18px"
        },
        payButtonWrapper: {
            margin: "42px 0 10px 0"
        },
        onlinePayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        },
        bankPayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        },
        streaming: {
            unauthorized: {
                fontSize: "2.60vw"
            }
        } 
    }
}