import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { TeacherAd } from "../../models/TeacherAd";
import { getAdRequestModel, getDistrictTowns, getGradeCategorySubjects, postAdSearchModel } from "../../services/TeacherService";
import { CardListLoader } from "../../loaders/CardListLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { NoData } from "../../components/Shared/NoData";
import { TeacherSearchResponseModel } from "../../models/TeacherSearchResponseModel";
import { Pagination } from "../../components/Shared/Pagination";
import { MenuOption } from "../../models/MenuOption";
import { Option } from "../../models/Option";
import { TeacherSearchRequestModel } from "../../models/TeacherSearchRequestModel";
import { SingleSelectMenuInput } from "../../components/Shared/Inputs/SingleSelectMenuInput";
import { InputLoader } from "../../loaders/InputLoader";
import { SingleSelectInput } from "../../components/Shared/Inputs/SingleSelectInput";
import { Switch } from "../../components/Shared/Inputs/Switch";
import { ReactComponent as DropdownIcon } from "../../assets/svgs/drop_down.svg";
import { getSeoName } from "../../helpers/Common";
import { Button } from "../../components/Shared/Button";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Teacher } from "../../models/Teacher";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";

export const AdListPage: React.FC = () => {    
    const [searchParams] = useSearchParams();  
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("teacherListPage")); 
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();   
    const [loading, setLoading] = useState<boolean>(true);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [isFilterLoad, setIsFilterLoad] = useState<boolean>(false);
    const [isFilterExpand, setIsFilterExpand] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [townLoading, setTownLoading] = useState<boolean>(false);    
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]);                 
    const [subjects, setSubjects] = useState<Option[]>([]);        
    const [mediums, setMediums] = useState<Option[]>([]);        
    const [classTypes, setClassTypes] = useState<Option[]>([]);        
    const [districts, setDistricts] = useState<Option[]>([]);         
    const [districtTowns, setDistrictTowns] = useState<MenuOption[]>([]);        
    const [includeOnline, setIncludeOnline] = useState<boolean>(true);
    const [teacherAds, setTeacherAds] = useState<TeacherAd[]>([]);
    const [filteredTeacherAds, setFilteredTeacherAds] = useState<TeacherAd[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });        
    const gradeCategoryId: number = searchParams.get("gradeCategoryId") === null ? -1 : +searchParams.get("gradeCategoryId")!;
    const gradeId: number = searchParams.get("gradeId") === null ? -1 : +searchParams.get("gradeId")!;
    const subjectId: number = searchParams.get("subjectId") === null ? -1 : +searchParams.get("subjectId")!;
    const mediumId: number = searchParams.get("mediumId") === null ? -1 : +searchParams.get("mediumId")!;
    const classTypeId: number = searchParams.get("classTypeId") === null ? -1 : +searchParams.get("classTypeId")!;
    const districtId: number = searchParams.get("districtId") === null ? -1 : +searchParams.get("districtId")!;
    const townId: number = searchParams.get("townId") === null ? -1 : +searchParams.get("townId")!;        
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();
    const [totalAdCount, setTotalAdCount] = useState<number>();    
    const pageSize: number = 100;
    const subjectCharacterLength: number = isMobile ? 42 : 84;    
    const districtCharacterLength: number = isMobile ? 42 : 84;
    const [countDisplay, setCountDisplay] = useState<boolean>(false);
    const scrollElementRef = useRef<any>();
    const bannerType = isMobile ? 7 : 8;

    useEffect(() => {
        getLocalizations();          
        getRequestModelData();  
        expandFilterPanel();            
    }, []);

    useEffect(() => {                    
        getTeacherAdList();                     
    }, [page, gradeId, subjectId, mediumId, classTypeId, districtId, townId, includeOnline]);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("teacherListPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 20) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getTeacherAdList() {          
        let responseModel: TeacherSearchResponseModel = {
            PageNo: page,
            RecordsPerPage: pageSize,
            GradeCategoryId: gradeCategoryId,
            GradeId: gradeId,
            SubjectId: subjectId,
            MediumId: mediumId,
            ClassTypeId: classTypeId,
            DistrictId: districtId,
            TownId: townId,
            IncludeOnline: includeOnline
        }
        await postAdSearchModel(responseModel)
        .then((teacherAdList: TeacherAd[]) => {
            setTeacherAds(teacherAdList);   
            setFilteredTeacherAds(teacherAdList); 
            if(teacherAdList.length > 0) { 
                let totalPageCount = Math.ceil(teacherAdList[0].RecordCount / pageSize);            
                setTotalPages(totalPageCount);
                setTotalAdCount(teacherAdList[0].RecordCount); 
                enableCountDisplay();
            }    
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }
    
    async function getRequestModelData() {
        let intialGradeCategoryId = gradeCategoryId === -1 ? "" : gradeCategoryId.toString();
        let initialDistrictId = districtId === -1 ? "" : districtId.toString();        
        await getAdRequestModel(intialGradeCategoryId, initialDistrictId)
        .then((requestModel: TeacherSearchRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);
            setDistricts(requestModel.Districts);
            setDistrictTowns(requestModel.DistrictTowns);            
            setIsFilterLoad(true);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    async function getGradeCategorySubjectList(gradeCategoryId: string) {                
        await getGradeCategorySubjects(gradeCategoryId)
        .then((subjectList: Option[]) => {            
            setSubjects(subjectList);
            setSubjectLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    async function getDistrictTownList(districtId: string) {                
        await getDistrictTowns(districtId)
        .then((districtTownList: MenuOption[]) => {            
            setDistrictTowns(districtTownList);
            setTownLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    const setSelectedGrade = (gradeCategoryId: number, selectedGradeId: number) => {  
        setSubjectLoading(true);
        if(gradeCategoryId !== -1) {                        
            getGradeCategorySubjectList(gradeCategoryId.toString());
        }
        else {
            getGradeCategorySubjectList("");
        }      
        searchParams.set("gradeCategoryId", gradeCategoryId.toString());
        searchParams.set("gradeId", selectedGradeId.toString());
        searchParams.delete("subjectId");
        handleNavigate();
    }

    const setSelectedSubject = (selectedSubjectId: number) => {        
        searchParams.set("subjectId", selectedSubjectId.toString());
        handleNavigate();
    }

    const setSelectedMedium = (selectedMediumId: number) => {        
        searchParams.set("mediumId", selectedMediumId.toString());
        handleNavigate();
    }

    const setSelectedClassType = (selectedClassTypeId: number) => {        
        searchParams.set("classTypeId", selectedClassTypeId.toString());
        handleNavigate();
    }

    const getSelectedDistrict = (districtId: number) => {
        setTownLoading(true);        
        searchParams.set("districtId", districtId.toString());        
        searchParams.delete("townId");
        if(districtId !== -1) {                        
            getDistrictTownList(districtId.toString());
        }
        else {
            getDistrictTownList("");
        }
        handleNavigate();
    }

    const setSelectedTown = (districtId: number, selectedTownId: number) => {
        searchParams.set("districtId", districtId.toString());         
        searchParams.set("townId", selectedTownId.toString());
        handleNavigate();
    }

    const handleNavigate = () => {                                    
        const newUrl = "/teachers/" + getPageSEO() + `?${searchParams}`;
        navigate(newUrl);                
    }

    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString()); 
        scrollElementRef.current.scrollIntoView();
        handleNavigate();                                    
    }

    const enableCountDisplay = () => {
        if(gradeId === -1 && subjectId === -1 && mediumId === -1 && classTypeId === -1 && districtId === -1 && townId === -1) {
            setCountDisplay(false);
        } 
        else {
            setCountDisplay(true);
        }
    }

    const expandFilterPanel = () => {
        if(gradeId !== -1 || subjectId !== -1 || mediumId !== -1 || classTypeId !== -1 && districtId !== -1 && townId !== -1) {
            setIsFilterExpand(true);
        }
    }

    const toAdView = (teacherAd: TeacherAd) => {        
        let seo = getSeoName(teacherAd.TeacherName);
        navigate("/teacher-ads/view/" + teacherAd.TeacherAdId + "/" + seo);        
    }

    const getPageSEO = (): string => {        
        let gradeSEO = "", subjectSEO = "", mediumSEO = "", districtSEO = "sri lanka", townSEO = "";                
        if(searchParams.get("gradeId") !== null && searchParams.get("gradeId") !== '-1') {     
            let selectedGradeCategoryId = +searchParams.get("gradeCategoryId")!;
            let selectedGradeId = +searchParams.get("gradeId")!;       
            gradeSEO = categoryGrades.find(x => x.LabelId === selectedGradeCategoryId)!.Options.find(y => y.Value === selectedGradeId)!.SecondaryOption!;
        }
        if(searchParams.get("subjectId") !== null && searchParams.get("subjectId") !== '-1') {                 
            let selectedSubjectId = +searchParams.get("subjectId")!;                
            subjectSEO = subjects.find(x => x.Value === selectedSubjectId)!.SecondaryOption!;
        }
        if(searchParams.get("mediumId") !== null && searchParams.get("mediumId") !== '-1') {                 
            let selectedMediumId = +searchParams.get("mediumId")!;                
            mediumSEO = mediums.find(x => x.Value === selectedMediumId)!.SecondaryOption!;
        }
        if(searchParams.get("districtId") !== null && searchParams.get("districtId") !== '-1') {                 
            let selectedDistrictId = +searchParams.get("districtId")!;                
            districtSEO = districts.find(x => x.Value === selectedDistrictId)!.SecondaryOption!;
        }
        if(searchParams.get("townId") !== null && searchParams.get("townId") !== '-1') {  
            let selectedDistrictId = +searchParams.get("districtId")!;                
            let selectedTownId = +searchParams.get("townId")!;                
            townSEO = districtTowns.find(x => x.LabelId === selectedDistrictId)!.Options.find(y => y.Value === selectedTownId)!.SecondaryOption!;
        }
        let pageSEO = `${gradeSEO} ${subjectSEO} ${mediumSEO} tuition classes in ${districtSEO} ${townSEO}`;
        pageSEO = pageSEO.trim();
        return getSeoName(pageSEO);
    }

    const toMyClasses = () => {        
        navigate('/teacher-ads/my-ads');
    }

    return (
        <>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
                    {teacher && <Button style={styles.myAdButton} onClick={toMyClasses}>{"My Ads"}</Button>}
                    <div style={{...styles.filter, ...(isFilterExpand && styles.filter.expand)}}>
                        <div style={{...styles.filterExpandButton, ...(isFilterExpand && styles.filterExpandButton.expand)}} onClick={() => setIsFilterExpand((prev) => !prev)}>
                            {"Search Filters"}
                            <DropdownIcon style={{...styles.expandButtonIcon, ...(isFilterExpand && styles.expandButtonIcon.up)}}/>
                        </div>
                        {(isFilterLoad && isFilterExpand) && <div style={styles.filterContent}>
                            <SingleSelectMenuInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}
                                menuOptions={categoryGrades}                    
                                selectedOptionId={gradeId}
                                setSelectedOption={setSelectedGrade} 
                                searchEnabled={true}
                            />
                            {subjectLoading ? <InputLoader /> :
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                                options={subjects} 
                                selectedId={subjectId}
                                setSelected={setSelectedSubject}
                                searchEnabled={true}
                            />}
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}                        
                                options={mediums} 
                                selectedId={mediumId}
                                setSelected={setSelectedMedium}
                            />
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}                        
                                options={classTypes} 
                                selectedId={classTypeId}
                                setSelected={setSelectedClassType}
                            />                
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwDistrictLabel')?.ElementValue!}                        
                                options={districts} 
                                selectedId={districtId}
                                setSelected={getSelectedDistrict}
                                searchEnabled={true}
                            />
                            {townLoading ? <InputLoader /> :
                            <SingleSelectMenuInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwTownLabel')?.ElementValue!}
                                menuOptions={districtTowns}                     
                                selectedOptionId={townId}                    
                                setSelectedOption={setSelectedTown}
                                searchEnabled={true}
                            />}
                            {(districtId !== -1 || townId !== -1) && <div style={styles.switch.wrapper} onClick={() => setIncludeOnline(!includeOnline)}>
                                <span style={styles.switch.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextOnline')?.ElementValue!}</span>
                                <Switch checked={includeOnline}/>                
                            </div>}                    
                        </div>}
                    </div>                        
                    <div style={styles.listContainer} ref={scrollElementRef}>
                        {loading ? <CardListLoader/> : <>
                            {teacherAds.length === 0 ? <NoData/> : <>
                                {countDisplay && <div style={styles.listCount}>{totalAdCount + " Teachers"}</div>}                        
                                {filteredTeacherAds.map((teacherAd, index) =>
                                    <div key={index} style={styles.adCard} onClick={() => toAdView(teacherAd)}>                                
                                        <div>
                                            <img src={teacherAd.TeacherAdUrl} alt="ad image" style={styles.adCard.image}></img>
                                        </div>
                                        <div style={styles.adCard.detailWrapper}>
                                            <div style={styles.adCard.name}>{teacherAd.TeacherName}</div>
                                            {teacherAd.Subjects && <div style={styles.adCard.subjects}>
                                                {teacherAd.Subjects.length > subjectCharacterLength ? teacherAd.Subjects.slice(0, subjectCharacterLength) + " ..." : teacherAd.Subjects}
                                            </div>}                                    
                                            {teacherAd.Districts && <div style={styles.adCard.districts}>
                                                {"Physical: "}{teacherAd.Districts.length > districtCharacterLength ? teacherAd.Districts.slice(0, districtCharacterLength) + " ..." : teacherAd.Districts} 
                                            </div>}
                                            <div style={styles.adCard.type}>
                                                {teacherAd.Online && <span>{"Online: All Island"}</span>}
                                            </div> 
                                            <div style={styles.adCard.see}>{"See More..."}</div>                                
                                        </div>
                                    </div>
                                )}
                                {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination} />}
                            </>}
                        </>}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>            
        </>
    )
}