import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { ToolBar } from "../../components/Shared/ToolBar";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getTeacherAdDetails } from "../../services/TeacherService";
import { Helmet } from "react-helmet-async";
import { TeacherAd } from "../../models/TeacherAd";
import { ReactComponent as Whatsapp } from "../../assets/svgs/whatsapp.svg";
import { ReactComponent as User } from "../../assets/svgs/user-profile.svg";
import { Localization } from "../../models/Localization";
import { getPageLocalizations } from "../../services/CommonService";
import { ClassDescriptionLoader } from "../../loaders/ClassDescriptionLoader";

export const AdViewPage: React.FC = () => {
    let params = useParams();
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("adViewPage"));    
    const [loading, setLoading] = useState<boolean>(true);    
    const [teacherAd, setTeacherAd] = useState<TeacherAd>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    
    useEffect(() => { 
        getLocalizations();        
        getTeacherAd();
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("adViewPage"));        
    }, [isMobile]);

    
    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 33) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }
    
    async function getTeacherAd() {        
        await getTeacherAdDetails(+params.adId!)
        .then((teacherAdDetails: TeacherAd) => {
            setTeacherAd(teacherAdDetails);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });        
    }
  
    const toProfile = (event: React.MouseEvent, teacherId: number) => {
        event.stopPropagation();
        navigate("/teacher-profile/" + teacherId);        
    }    

    const toWhatsapp = (event: React.MouseEvent, whatsappNo: string) => {
        event.stopPropagation();
        window.open(`https://wa.me/${whatsappNo}`, '_blank');
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            {loading ? <ClassDescriptionLoader/> : 
            <>
                <Helmet>
                    <title>{teacherAd?.TeacherName + " - tuteclass.com"}</title>
                    <meta name="description" content={teacherAd?.TeacherName + " tuition classes"}/>                    
                </Helmet>
                <div style={styles.container}>                    
                    <img src={teacherAd?.TeacherAdUrl} style={styles.image}></img> 
                    <div style={styles.detailContainer}>
                        <div style={styles.name} onClick={(e) => toProfile(e, teacherAd!.TeacherId)}>
                            {teacherAd?.TeacherName}
                            <User style={styles.name.icon}/>
                        </div> 
                        <div style={styles.tepNo} onClick={(e) => toWhatsapp(e, teacherAd!.WhatsappNo)}>
                            {teacherAd?.WhatsappNo}
                            <Whatsapp style={styles.tepNo.icon}/>                        
                        </div>
                        {teacherAd?.Subjects && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue}</div>
                            <div style={styles.tagWrapper}>{teacherAd?.Subjects.split(',').map((subject, index) => <div key={index} style={styles.tag}>{subject}</div>)}</div>
                        </div>}                       
                        {teacherAd?.Grades && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue}</div>                            
                            <div style={styles.tagWrapper}>{teacherAd?.Grades.split(',').map((grade, index) => <div key={index} style={styles.tag}>{grade}</div>)}</div>
                        </div>}
                        {teacherAd?.Mediums && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue}</div>                            
                            <div style={styles.tagWrapper}>{teacherAd?.Mediums.split(',').map((medium, index) => <div key={index} style={styles.tag}>{medium}</div>)}</div>
                        </div>}
                        {teacherAd?.ClassTypes && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue}</div>                            
                            <div style={styles.tagWrapper}>{teacherAd?.ClassTypes.split(',').map((classType, index) => <div key={index} style={styles.tag}>{classType}</div>)}</div>
                        </div>}
                        {teacherAd?.Districts && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwDistrictLabel')?.ElementValue}</div>                            
                            <div style={styles.tagWrapper}>{teacherAd?.Districts.split(',').map((district, index) => <div key={index} style={styles.tag}>{district}</div>)}</div>
                        </div>}
                        {teacherAd?.Towns && <div>
                            <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTownLabel')?.ElementValue}</div>                            
                            <div style={styles.tagWrapper}>{teacherAd?.Towns.split(',').map((town, index) => <div key={index} style={styles.tag}>{town}</div>)}</div>
                        </div>}
                    </div>
                </div>
            </>}
        </div>
    )
}