import { Link, useNavigate } from "react-router-dom";
import { RegisterModel, RegisterValidation } from "../../models/RegisterModel";
import { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthContextProvider";
import { validateEmail, validatePhoneNumber } from "../../helpers/Common";
import { postStudentRegistration } from "../../services/StudentService";
import { ResponseModel } from "../../models/ResponseModel";
import { FormTexts } from "../../helpers/LayoutTexts";
import { RegisterFormLoader } from "../../loaders/RegisterFormLoader";
import { Input } from "../Shared/Inputs/Input";
import { Button } from "../Shared/Button";

interface Props {
    styles: any; 
    callbackUrl: string | null;
    message: string | null;
    changeStage(stage: string): void;
    openAlert(status: string, message: string): void;
}

const initialRegisterModel: RegisterModel = {    
    FirstName: "",
    LastName: "",
    WhatsappNo: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    PrivacyPolicy: true
}

const initialRegisterValidation: RegisterValidation = {
    FirstName: {},
    LastName: {},
    WhatsappNo: {},
    Email: {},
    Password: {},
    ConfirmPassword: {},
    PrivacyPolicy: {valid: true}
}

export const Register: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();    
    const [registerModel, setRegisterModel] = useState<RegisterModel>(initialRegisterModel);
    const [validation, setValidation] = useState<RegisterValidation>(initialRegisterValidation);            
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);
    // const [toErrorPage] = useErrorPage();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setRegisterModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.checked;
        setRegisterModel((values: any) => ({ ...values, [name]: value }));
        if(name === "PrivacyPolicy" && !value) {            
            setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "You should agree to Privacy Policy & Terms/Conditions"}}));            
        }      
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "FirstName" || name === "LastName") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }            
        }
        if(name === "WhatsappNo") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "WhatsApp number is required"}}));
            } 
            else if (!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid WhatsApp number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Password") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "ConfirmPassword") {
            const password = registerModel.Password;      
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}}));
            } 
            else if (password !== value) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }       
    }    

    const validateForm = (): number => {
        let formValidation: RegisterValidation = {
            FirstName: {valid: true},
            LastName: {valid: true},
            WhatsappNo: {valid: true},
            Email: {valid: true},
            Password: {valid: true},
            ConfirmPassword: {valid: true},
            PrivacyPolicy: {valid: true}          
        }
        if(registerModel.FirstName === "") {
            formValidation.FirstName = {valid: false, invalid: true, invalidMessage: "First name is required"};
        }
        if(registerModel.LastName === "") {
            formValidation.LastName = {valid: false, invalid: true, invalidMessage: "Last name is required"};
        }       
        if(registerModel.WhatsappNo === "") {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "WhatsApp number is required"};
        } 
        else if(!validatePhoneNumber(registerModel.WhatsappNo)) {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "Invalid WhatsApp number!"};
        } 
        if(registerModel.Email === "") {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if(!validateEmail(registerModel.Email)) {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        if(registerModel.Password === "") {
            formValidation.Password = {valid: false, invalid: true, invalidMessage: "Password is required"};
        }
        if(registerModel.ConfirmPassword === "") {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password confirmation is required"};
        } 
        else if(registerModel.Password !== registerModel.ConfirmPassword) {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"};
        }     
        if(!registerModel.PrivacyPolicy) {
            formValidation.PrivacyPolicy = {valid: false, invalid: true, invalidMessage: "You should agree to Privacy Policy & Terms/Conditions"};
        }   
        setValidation(formValidation);        
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;
    }

    async function submitRegistration(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;
        if(validInputCount < 7) {
            validInputCount = validateForm();
        }
        if(validInputCount === 7) {     
            setLoading(true);                   
            await postStudentRegistration(registerModel)
            .then((registerResponse: ResponseModel) => {
                setLoading(false);
                if(registerResponse.Status) {
                    console.log(registerResponse);
                    authContext.setLoggedStudent(registerResponse.Data, registerResponse.Token); 
                    if(props.callbackUrl) {
                        navigate(props.callbackUrl);
                    } 
                    else {
                        navigate('/');
                    }
                }
                else {
                    props.openAlert("error", registerResponse.Message);
                }
            })
            .catch((error) => {            
                // toErrorPage(error);
            });
        }
        else {                        
            props.openAlert("error", FormTexts.submitError);        
        }
    }

    return (
        <>
            {loading ? <RegisterFormLoader/> :
            <form style={{...styles.registerForm, ...(props.message && styles.registerForm.messageEnabled)}} onSubmit={submitRegistration}>
                <div style={styles.accordionButtonsContainer}>
                    <div
                        style={{...styles.accordionButton, ...styles.accordionButton.left}}
                        onClick={() => props.changeStage("login")} >
                        Login
                    </div>
                    <div
                        style={{...styles.accordionButton, ...styles.accordionButton.right, ...styles.accordionButton.selected}}
                        onClick={() => props.changeStage("register")} >
                        Register
                    </div>
                </div>
                {props.message && <div style={styles.callbackMessage}>{props.message}</div>}
                <p style={styles.boldTitle}>{"Students Register"}</p>
                <div style={styles.row}>
                    <div style={styles.rowSection}>
                        <Input
                            label={'First Name *'}
                            type={'text'}
                            name={'FirstName'}
                            value={registerModel.FirstName}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.FirstName}                    
                        />
                    </div>
                    <div style={styles.rowSection}>
                        <Input
                            label={'Last Name *'}
                            styles={styles.registerLeftInput}
                            type={'text'}                    
                            name={'LastName'}
                            value={registerModel.LastName}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.LastName}
                        />
                    </div>
                </div>
                <Input
                    label={'WhatsApp Number *'}
                    type={'text'}
                    name={'WhatsappNo'}
                    value={registerModel.WhatsappNo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.WhatsappNo}
                />
                <Input
                    label={'Email *'}
                    type={'text'}
                    name={'Email'}
                    value={registerModel.Email}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.Email}
                />
                <div style={styles.row}>
                    <div style={styles.rowSection}>
                        <Input
                            label={'Password *'}
                            type={'password'}
                            name={'Password'}
                            value={registerModel.Password}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.Password}
                        />
                    </div>
                    <div style={styles.rowSection}>
                        <Input
                            label={'Confirm Password *'}
                            styles={styles.registerLeftInput}
                            type={'password'}
                            name={'ConfirmPassword'}                    
                            value={registerModel.ConfirmPassword}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            validation={validation.ConfirmPassword}
                        />
                    </div>
                </div>                                
                <div style={styles.policyWrapper}>
                    <div style={{...styles.row,...(!registerModel.PrivacyPolicy && styles.policyLabelError)}}>
                        <input 
                            type={'checkbox'}
                            name={'PrivacyPolicy'}
                            checked={registerModel.PrivacyPolicy}
                            onChange={handleCheckboxChange}                           
                        />
                        <span style={styles.policyLabel}>I agree to tuteclass <Link style={{...styles.sharpText.black,...(!registerModel.PrivacyPolicy && styles.policyLabelError)}} to={'/termsAndConditions'}>Terms and Conditions.</Link></span>
                    </div>                               
                </div>
                <div style={styles.row}>
                    <div style={styles.registerWrapper}>
                        <Button style={styles.submitButton} type="submit" disabled={loading}>Register</Button>
                        <p style={styles.loginNowWrapper}>Already have an account ? <span style={styles.sharpText.bold} onClick={() => props.changeStage("login")}>Login Now</span></p>
                    </div>
                    {/* <div style={styles.social}>
                        <p style={styles.social.registerUsing.text}>Or Register Using</p>
                        <div style={styles.social.registerUsing}>
                            <AuthenticateBySocialApps styles={styles.social} callbackUrl={props.callbackUrl} openAlert={props.openAlert} showCvUploadPopup={props.showCVUploadPopup}/>
                        </div>
                    </div> */}
                </div>
            </form>}
        </>
    )
}