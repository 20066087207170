import { Color } from "../enums/Color";

export const newAds = {
    mobile: {        
        desktopTitle: {
            display: "none"
        },       
        container: {
            display: "flex",
            flexDirection: "column" as "column",            
            padding: "0 5%"            
        },
        content: {
            width: "100%"
        },  
        subContainer: {
            marginTop: "128px"
        },      
        input: {
            wrapper: {                                        
                margin: "60px auto 0 auto" 
            }
        },
        searchButton: {
            height: "50px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px 0"
        },
        imageWarning: {
            margin: "30px 0 5px 0",
            fontSize: "12px"
        },
        imageInput: {
            height: "auto",  
            width: "-webkit-fill-available",            
            border: "none",
            borderRadius: "5px",            
            objectFit:"cover",
            cursor: "pointer"
        },
        imageInvalid: {
            border: "1px solid " + Color.Red,            
            WebkitFilter: "grayscale(1)", 
            filter: "grayscale(1)"
        },
        invalidMessage: {
            color: Color.Red,
            fontSize: "14px",
            fontWeight: "700",
            paddingLeft: "10px"
        },
        banner: {
            marginTop: "20px"
        },
        paymentDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            container: {
                background: Color.White,
                position: "fixed",
                top: "35%",
                right: "5%",
                width: "80%",
                height: "auto",
                padding: "10px 5%",                        
                borderRadius: "10px",
                zIndex: "2",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)"
            }
        },
        paymentMessage: {
            fontSize: "18px"
        },
        payButtonWrapper: {
            display: "flex",
            flexDirection: "column",
            margin: "24px 0 10px 0"
        },
        onlinePayButton: {
            height: "40px",
            background: Color.MediumGreen,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        },
        bankPayButton: {
            height: "40px",
            background: Color.Black,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        },
        cashPayButton: {
            height: "40px",            
            background: Color.Blue,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold"            
        },
        promoWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
        },
        promoInput: {
            height: "4.76vh",
            width: "-webkit-fill-available",
            borderRadius: "1.2vw",
            padding: "0 2.78vw",
            backgroundColor: Color.White,
            border: "1px solid " + Color.SharpAsh
        },
        promoInvalidInput: {
            border: "1px solid " + Color.Red
        },
        applyButton: {
            width: "22.70vw",
            height: "4.76vh",
            border: "2px solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",            
            fontWeight: "bold" as "bold",                     
            borderRadius: "1.2vw",            
            marginLeft: "4.36vw"
        },
        promoInvalidMessage: {            
            fontWeight: "bold" as "bold",
            margin: "1.00vh 0 0 2.78vw",
            color: Color.Red
        },
        discountLabel: {   
            display: "flex",
            margin: "3vw 0 0 2.78vw",
            fontSize: "3.35vw",                
            alignItems: "center"
        },
        discount: {
            fontWeight: "bold" as "bold",
            fontSize: "4.35vw",
            marginLeft: "auto"
        }
    },
    desktop: {
        desktopTitle: {
            display: "block",
            textAlign: "center" as "center",
            color: Color.Green            
        },        
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        },   
        content: {
            width: "60%"
        },  
        subContainer: {
            marginTop: "60px"
        },    
        input: {
            wrapper: {                                        
                margin: "60px auto 0 auto" 
            }
        },
        searchButton: {
            height: "60px",
            width: "-webkit-fill-available",            
            borderRadius: "10px",            
            fontSize: "20px",
            marginTop: "40px"
        },
        imageInput: {
            height: "auto",
            borderRadius: "10px"            
        },        
        banner: {
            width: "40%",
            margin: "60px 0 0 8%"
        },
        paymentDialog: {
            container: {
                top: "28%",
                right: "unset",
                width: "32.6%",
                padding: "2% 1.7%",
                borderRadius: "10px",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
            }
        },
        paymentMessage: {
            fontSize: "18px"
        },
        payButtonWrapper: {
            margin: "42px 0 10px 0"
        },
        onlinePayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        },
        bankPayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        },
        promoInput: {
            height: "50px",
            borderRadius: "0.52vw",
            padding: "0 0.70vw",
            fontSize: "1.12vw"
        },
        applyButton: {
            height: "50px",
            width: "8.07vw",            
            fontSize: "1.12vw",            
            borderRadius: "0.52vw",            
            marginLeft: "1.36vw"
        },
        promoInvalidMessage: {            
            margin: "1.00vh 0 0 0.70vw"
        },        
        discountLabel: {            
            margin: "1vw 0 0 0.70vw",
            fontSize: "1vw"            
        },
        discount: {            
            fontSize: "1.35vw"            
        }
    }
}